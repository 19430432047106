import React from 'react'
import Navbar from "react-bootstrap/Navbar";
import SmartPearBrand from "./SmartPearBrand";

export default function SmartPearBrandFooter({ clientName }) {
  return (
    <div>
      <br/><br/>
      <div><b>Smart Meter Services powered by:</b></div>
      <Navbar expand="lg" className="justify-content-center">
        <SmartPearBrand clientName={clientName}/>
      </Navbar>
    </div>
  )
}