import React from 'react';
import {Fragment} from "react";
import DeviceIdLink from './DeviceIdLink'
import {spaceCamel} from "../common/commonUtilities";
import {IDENTIFIERS, UNKNOWN_DEVICE_TYPE, MPAN, MPRN} from "../common/constants";
import Table from "react-bootstrap/Table";
import {isIdentifier} from "../common/commonUtilities";

// display an object's keys and values as a table
export default function ObjectToTableContents({targetObject, setDeviceLoaded, setDeviceId }) {
  // key is a going to be a string eg 'MPAN',
  // target object a hash (map?/dictionary?) eg {MPAN: '2000000067135', post_code: 'W7 2EN', address: '28,,ROSEBANK ROAD,,HANWELL,LON', MPxNDeviceDetails: {…}}
  const identifierKey = (key) => {
    return IDENTIFIERS.includes(key)
  }

  const deviceTypeForLink = (targetObject) => {
    if (targetObject["deviceType"]) {
      return targetObject["deviceType"]
    }
    if (targetObject[MPAN]) {
      return MPAN
    }
    if (targetObject[MPRN]) {
      return MPRN
    }
  }

  return (
    <Fragment>
      {Object.keys(targetObject).map(key => {
        return (
          <tr key={key}>
            <td >{(isIdentifier(key)) ?
              <DeviceIdLink setDeviceId={setDeviceId} setDeviceLoaded={setDeviceLoaded} deviceId={key} deviceType={targetObject[key]}/> : spaceCamel(key)}</td>
            <td>
              {((typeof (targetObject[key]) === 'object') && (targetObject[key] !== null)) ?
                <Table striped bordered hover size="sm" className="d-flex m-0">
                  <tbody><ObjectToTableContents targetObject={targetObject[key]} setDeviceLoaded={setDeviceLoaded} setDeviceId={setDeviceId}/>
                  </tbody>
                </Table> :
                (identifierKey(key) && !targetObject["client_id"]) ?
                  <DeviceIdLink setDeviceId={setDeviceId} setDeviceLoaded={setDeviceLoaded} deviceId={targetObject[key]} deviceType={deviceTypeForLink(targetObject) || UNKNOWN_DEVICE_TYPE}/> :
                  targetObject[key]}
            </td>
          </tr>
        )
      })}
    </Fragment>
  )
}