import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {resetFocus} from "../common/commonUtilities";
import {TRANSACTION_BUTTON} from "../common/commonUtilities";

export default function ExtraInfo({title, content, show, onHide}) {
  return (
    <Modal

      show={show}
      onHide={onHide}
      onEntered={resetFocus}
      centered
      size='xl'
      backdrop='static'>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre style={{overflow: 'scroll', maxHeight: '80vh'}}>{content}</pre>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          variant="primary"
          id={TRANSACTION_BUTTON}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
