import React, {Fragment, useEffect, useState} from 'react';
import SmartDateTime from "./SmartDateTime";
import {
  NO_ERROR,
  SOME_ERROR,
  START_DATE,
  START_TIME,
  SINGLE_FIELD,
  CROSS_FIELD
} from "../../common/constants";

export const revalidateElements = (error, ...elements) => {
  for (let element of elements) {
    document.getElementById(element).setCustomValidity((error === NO_ERROR) ? NO_ERROR : SOME_ERROR)
  }
}

export default function SmartSwitchedOnPeriod(props) {

  const [startDateTimeValue, setStartDateTimeValue] = useState(null);
  const [endDateTimeValue, setEndDateTimeValue] = useState(null);
  const [startDateTimeErrorType, setStartDateTimeErrorType] = useState(SINGLE_FIELD);
  const [endDateTimeErrorType, setEndDateTimeErrorType] = useState(SINGLE_FIELD);

  useEffect(() => updateCrossFieldValidation(), [startDateTimeValue, endDateTimeValue])


  const updateCrossFieldValidation = () => {
    if ((startDateTimeErrorType !== SINGLE_FIELD) && (endDateTimeErrorType !== SINGLE_FIELD)) {
      revalidateElements(((startDateTimeValue > endDateTimeValue) ? SOME_ERROR : NO_ERROR), START_DATE, START_TIME)
    } else if ((startDateTimeErrorType === CROSS_FIELD) && (endDateTimeErrorType === SINGLE_FIELD)) {
      revalidateElements(NO_ERROR, START_DATE, START_TIME)
    }
  }

  return (
    <Fragment> <u>{`period_${props.period_index}`}</u>
      <SmartDateTime
        name={`start_${props.period_index}`}
        label="UTC Start"
        optional={true}
        minDateTime={props.minDateTime}
        maxDateTime={props.maxDateTime}
        setDateTimeValue={setStartDateTimeValue}
        dateTimeErrorType={startDateTimeErrorType}
        setDateTimeErrorType={setStartDateTimeErrorType}
      />
      <SmartDateTime
        name={`end_${props.period_index}`}
        label="UTC End"
        optional={true}
        minDateTime={props.minDateTime}
        maxDateTime={props.maxDateTime}
        setDateTimeValue={setEndDateTimeValue}
        dateTimeErrorType={endDateTimeErrorType}
        setDateTimeErrorType={setEndDateTimeErrorType}
      />
    </Fragment>
  )
}


