import React, { Fragment, useState, useEffect } from 'react'
import { Alert, Button } from 'react-bootstrap';
import UploadGroup from "./UploadGroup";
import PathBreadcrumb from "./PathBreadcrumb";
import BucketList from "./BucketList";
import { reverseTimestamp } from "../common/commonUtilities";
import { list, uploadData } from 'aws-amplify/storage';
const NO_VALID_EXTENSIONS = "NO_VALID_EXTENSIONS"

export default function Repository(props) {
  const [loadingBucketList, setLoadingBucketList] = useState(false);
  const [loadingBucketListError, setLoadingBucketListError] = useState(false);
  const [canUploadFile, setCanUploadFile] = useState(false);
  const [selectedPath, setSelectedPath] = useState("/");
  const [keys, setKeys] = useState([]);
  const [pageTokens, setPageTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 1000

  // trigger a load by setting loadingBucketList
  useEffect(() => {
    if (loadingBucketList) loadFirstBatch()
  }, [loadingBucketList]);

  // on initial load and whenever selectedPath changes, to trigger a reload
  useEffect(() => {
    setLoadingBucketList(true)
    setPageTokens([])
  }, [selectedPath])

  // @return [Array of Strings] the list of sub folders in selectedFolder ending /
  const folders = () => {
    const pathToUse = (selectedPath === '') ? '/' : selectedPath;
    const rootFolders = props.config.allowed_folders.map(folder => folder.split('/')[0] + '/');
    const subFolders = props.config.allowed_folders.filter(folder => folder.includes(pathToUse)).filter(folder => ((folder !== '') && (folder !== pathToUse)))
    const candidates = (pathToUse === '/') ? rootFolders : subFolders;
    // remove duplicates
    return Array.from(new Set(candidates));
  }

  // store either the list of subfolders or page tokens of s3 keys of length pageSize matching the selectedPath in state (that are within the uploads folder)
  // this needs the IAM permissions on the corresponding role
  const loadFirstBatch = async () => {
    const subFolders = folders();
    if (subFolders.length !== 0) {
      // set the keys just to be a list of allowed folders
      setCanUploadFile(false);
      setKeys(subFolders);
      setLoadingBucketListError(false);
    } else {
      let nextToken = undefined;
      let hasNextPage = true;
      let pageNumber = 1;
      const tempPageTokens = [];

      while (hasNextPage) {
        const listOptions = {
          pageSize: pageSize,
        };
        if (nextToken) {
          listOptions.nextToken = nextToken;
        }
        const response = await list({prefix: selectedPath, selectedPath, options: listOptions}).catch(() => {
          setKeys([]);
          setLoadingBucketListError(true);
        });
        tempPageTokens.push({ pageNumber, nextToken });
        if (response.nextToken) {
          nextToken = response.nextToken;
        } else {
          nextToken = undefined;
          hasNextPage = false;
        }
        pageNumber++;
      }
      setPageTokens(tempPageTokens);
      loadKeysForPage(1)
    }
    setLoadingBucketList(false);
  };

  // store the list of s3 keys matching the selectedPath in state (that are within the uploads folder)
  // this needs the IAM permissions on the corresponding role
  const loadKeysForPage = async (pageNumber) => {
    setCurrentPage(pageNumber);
    const listOptions = {
      pageSize: pageSize,
    };
    // if no pages are present in state yet, the first page is being loaded, so no continuation token is needed
    if (pageTokens.length > 0) {
      const page = pageTokens.find((p) => p.pageNumber === pageNumber)
      if (page.nextToken) {
        listOptions.nextToken = page.nextToken;
      }
    }
    const response = await list({prefix: selectedPath, selectedPath, options: listOptions}).catch(() => {
      setKeys([]);
      setLoadingBucketListError(true);
    });
    setLoadingBucketListError(false);
    setCanUploadFile(true);
    setKeys(response.items.map(s3Object => s3Object.key).filter(key => key !== selectedPath))
  };

  // return a comma separated list of valid file extensions in a string, dependent on selectedPath
  const acceptedFileExtensions = () => {
    const folderToFileExtensionsMap = {
      zip_files: ".zip",
      advance_shipping_notices: ".xlsx,.xlsm,.txt,.csv,.tsv",
      dcc_data_files: ".xlsx,.xlsm,.txt,.csv",
      ad_hoc_reports: NO_VALID_EXTENSIONS,
      han_data_files: ".xlsx,.xlsm,.txt,.csv",
    };
    for (const folder in folderToFileExtensionsMap) {
      if (selectedPath.includes(folder)) {
        return folderToFileExtensionsMap[folder];
      }
    }
    return "*";
  }

  const uploadNewFile = async (file_selected) => {
    try {
      await uploadData({
        key: selectedPath + reverseTimestamp() + file_selected.name,
        data: file_selected,
        options: {
          contentType: file_selected.type
        }
      })
    } catch (e) {
      console.log(e);
    }
    setLoadingBucketList(true);
  }

  const renderPageNumbers = () => {
    const totalPages = pageTokens.length;
    const maxPagesToShow = 3;
    const surroundPages = 1;

    const getPageItem = (pageNumber) => (
      <Button
          key={pageNumber}
          onClick={() => loadKeysForPage(pageNumber)}
          disabled={pageNumber === currentPage}
          className="mr-2"
          variant="primary"
      >
        {pageNumber}
      </Button>
    );
    let displayedPages = [];
    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        displayedPages.push(getPageItem(i));
      }
    } else {
      const firstPage = getPageItem(1);
      const lastPage = getPageItem(totalPages);
      const leftEllipsis = <span key="left-ellipsis" className="mr-2" style={{ color: "#0d6efd", fontWeight: "bold" }}> ... </span>;
      const rightEllipsis = <span key="right-ellipsis" className="mr-2" style={{ color: "#0d6efd", fontWeight: "bold" }}> ... </span>;
      const middlePages = [];
      for (
          let i = Math.max(2, currentPage - surroundPages);
          i <= Math.min(totalPages - 1, currentPage + surroundPages);
          i++
      ) {
        middlePages.push(getPageItem(i));
      }
      if (currentPage <= maxPagesToShow - surroundPages) {
        displayedPages = [
          firstPage,
          ...middlePages,
          rightEllipsis,
          lastPage,
        ];
      } else if (currentPage >= totalPages - maxPagesToShow + surroundPages + 1) {
        displayedPages = [
          firstPage,
          leftEllipsis,
          ...middlePages,
          lastPage,
        ];
      } else {
        displayedPages = [
          firstPage,
          leftEllipsis,
          ...middlePages,
          rightEllipsis,
          lastPage,
        ];
      }
    }
    return displayedPages;
  };

  return (
    <Fragment>
      {canUploadFile && !(acceptedFileExtensions(selectedPath) === NO_VALID_EXTENSIONS) && (
        <UploadGroup
          accept={acceptedFileExtensions(selectedPath)}
          selectedPath={selectedPath}
          onUpload={uploadNewFile}/>)
      }
      <PathBreadcrumb
        selectedPath={selectedPath}
        onSelectPath={(path) => {
          setSelectedPath(path)
        }}/>
      <Alert>Warning: Smart Pear do not screen files for malicious content. Please ensure that you have suitable virus protection in place before downloading files from this portal.</Alert>
      {pageTokens.length > 1 && (
        <div style={{ minHeight: "40px" }}>
          <div style={{ textAlign: "right" }}>
            {renderPageNumbers()}
          </div>
        </div>)
      }
      <BucketList
        keys={keys}
        loading={loadingBucketList}
        error={loadingBucketListError}
        clientName={props.clientName}
        onSelectFolder={(path) => {
          setSelectedPath(path)
        }}
        setShowFileNotFound={props.setShowFileNotFound}/>
    </Fragment>
  )
}