import React from 'react'
import CookieConsent from "react-cookie-consent";
import Navbar from "react-bootstrap/Navbar";
import SmartPearBrand from "./SmartPearBrand";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";


export default function SmartPearHeader({email, signOut, changePassword, clientName}) {
  return (
    <div>
      <CookieConsent location="top">
        These services use cookies to secure user access.
      </CookieConsent>
      <div>
        <Navbar expand="lg">
          <SmartPearBrand clientName={clientName}/>
          <Navbar.Toggle
            title="Click to toggle drop down"
            aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end">
            <Nav>
              <div className="p-2">
                <Navbar.Text>
                  Signed in as: <strong>{email || ""}</strong>
                </Navbar.Text>
              </div>
              <NavDropdown.Divider/>
              <div className="p-0">
                <Button
                  title="Click to sign out"
                  onClick={signOut}>
                  Sign out
                </Button>
              </div>
              <div className="m-1"/>
              <div className="m-0">
                <Button
                  title="Click to change password"
                  onClick={changePassword}>
                  Change password
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  )
}