import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CRQ_FORM_FOCUS} from "../../common/constants";

//Generalised fragment for all variables that do not have fixed validation as a certain type to make consistent formatting
export default function SmartDate(props) {
  return (
    <Form.Group as={Row} className="p-2">
      <Form.Label column sm="3">{props.label}</Form.Label>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
        <Form.Control
          type="date"
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          required={props.required}
          min={props.min}
          max={props.max}
          title={props.title}/>
        <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}


