import React from 'react';
import './index.css';
import './init.js'
import SmartAccess from './SmartAccess';
import {configureAmplify} from './common/amplifyServices'
import {createRoot} from "react-dom/client";

import amplifyConfigs from './config/account-config.json';
export default amplifyConfigs;

const clientName = (import.meta.env.MODE === 'development') ? window.location.search.substr(1) : (window.location.hostname.split(".")[0])
const clientId = amplifyConfigs[clientName] && amplifyConfigs[clientName].bucketName

import(`./styles/${clientName}.css`).then(() => {
  console.log(`Theme loaded: ${clientName}`);
}).catch(() => {
  console.error(`No custom theme for ${clientName}`);
});

if (clientId) configureAmplify(clientName,amplifyConfigs);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<SmartAccess clientName={clientName} clientId={clientId}/>);