import React from 'react';
import Nav from "react-bootstrap/Nav";

// displays a box with a link to contact us. For use when there is no client access
export default function NoClientAccess() {
  return (
    <div className="border border-medium">
      <div className="p-1">
        <br/><br/>
        <Nav.Link
          href="https://www.smartpear.co.uk/contact"
          title="Click to contact us"
          target="_blank"
          rel="noopener noreferrer">Please contact us if you would like to use more of our services
        </Nav.Link>
        <br/><br/>
      </div>
    </div>
  )
}


