import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

const UPLOAD_BUTTON = "upload-button"
const HIDDEN_NEW_FILE = 'hidden-new-file'
const NO_ERROR = ""
const INFORMATION = 'initial'
const ERROR = 'red'
const NO_FILE = "Click select to choose a file to upload"

export default function UploadGroup(props) {

  const [filePicked, setFilePicked] = useState(NO_FILE);
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [errorMessage, setErrorMessage] = useState(NO_ERROR)
  const [errorState, setErrorState] = useState(INFORMATION)

  // when the user is picking potential files to upload - checks their selection
  const handleBrowseChange = (event) => {
    const file_selected = event.target.files[0];
    let error_type = INFORMATION
    let error = NO_ERROR
    if (file_selected.size > 5242880) {
      error = "File is above the 5 MB limit. Please break down into smaller files";
      error_type = ERROR
    } else if (!(/^[a-zA-Z0-9-._]{1,100}$/.test(file_selected.name))) {
      error = "Invalid filename: can be up to 100 characters using only letters, numbers, hyphens, full stops and underscores";
      error_type = ERROR
    } else {
      let good_extension = false;
      props.accept.split(',').forEach((ext) => {
        if (file_selected.name.endsWith(ext)) good_extension = true
      });
      if (!good_extension) {
        error = "Invalid filename: must end in " + props.accept;
        error_type = ERROR
      }
    }
    event.target.value = "";
    if (error === NO_ERROR) {
      setFilePicked(file_selected);
      document.getElementById(UPLOAD_BUTTON).focus()
    } else {
      setFilePicked(NO_FILE);
    }
    setUploadDisabled(error !== NO_ERROR);
    setErrorMessage(error)
    setErrorState(error_type)
  }

  const handleUpload = () => {
    setFilePicked(NO_FILE);
    setUploadDisabled(true);
    props.onUpload(filePicked);
    setErrorMessage(filePicked.name + " uploaded. Click select to upload another");
    setErrorState(INFORMATION)
  }

  return (
    <div className="border border-medium">
      <div className="p-1">
        <InputGroup>
          <Button
            title="Click to select a file for upload"
            onClick={() => document.getElementById(HIDDEN_NEW_FILE).click()}
          >Select</Button>
          <input
            type="file"
            id={HIDDEN_NEW_FILE}
            style={{display: "none"}}
            onChange={handleBrowseChange}
            accept={props.accept}/>
          <Form.Control
            as="input"
            id="selection-feedback"
            title="File names can be up to 100 characters using only letters, numbers, hyphens, full stops and underscores"
            value={(filePicked === NO_FILE) ? filePicked : filePicked.name}
            disabled/>
          <Button
            disabled={uploadDisabled}
            title="Click to upload the file"
            id={UPLOAD_BUTTON}
            onClick={handleUpload}
          >Upload</Button>
        </InputGroup>
        {(errorMessage !== NO_ERROR) && <div style={{display: "block"}}>
          <p style={{color: errorState, fontSize: "11px"}}>{errorMessage}</p>
        </div>}
      </div>
    </div>
  )
}
