// @param string [String] any string
// @return [String] the string with any reverse_timestamp removed
import {
  DEVICE_ID,
  GENERIC_DEFAULT,
  INVALID_IDENTIFER,
  MPAN,
  MPRN,
  NO_ERROR,
  SOME_ERROR,
  MPxNS
} from "./constants";

export function reverseTimestampRemoved(string) {
  return string.replace(/!\d{13}!/, '')
}

// @return [String] that alphabetically reduces as time increases. In s3, list_objects returns based on alphabetic sequence, lowest first
export function reverseTimestamp() {
  return `!${millisecondsToEpochEnd()}!`
}

// @return [Integer] milliseconds to the end of our epoch, so midnight on 01/01/2100
export function millisecondsToEpochEnd() {
  return (new Date('2100-01-01T00:00:00').getTime() - new Date().getTime())
}

// @return [Integer] milliseconds since the start of our epoch, so midnight on 01/01/2018
export function millisecondsSinceEpochStart() {
  return (new Date().getTime() - new Date('2018-01-01T00:00:00').getTime())
}

export const REACT_SELECT_INPUT = 'react-select-input'
export const SUBMITTED_BUTTON   = "submitted-button"
export const TRANSACTION_BUTTON = "transaction-button"

export const PASSWORD_REQUIREMENTS = "Your password must meet the following criteria: a minimum length as specified by your agreed password policy, including at least one uppercase letter, one lowercase letter, one number, and one special character."

// place the focus on the crq select drop down or the relevant part of a form displayed on top
export function resetFocus() {
  const visibleItem = document.getElementById(TRANSACTION_BUTTON) ||
    document.getElementById(SUBMITTED_BUTTON) ||
    document.getElementById(REACT_SELECT_INPUT);
  if (visibleItem) {visibleItem.focus()}
}

const duisTerms = {
  'ESME': 'ESME ',
  'MPx Ns': 'MPxNs ',
  'MPx N': 'MPxN ',
  'GSME': 'GSME ',
  'Mpxn': 'MPxN',
  'IHD': 'IHD ',
  'CAD': 'CAD ',
  'ASN1': 'ASN1 ',
  'CHF': 'CHF ',
  'CHTS': 'CHTS ',
  'CIN': 'CIN',
  'CSP': 'CSP ',
  'Co S': 'CoS ',
  'CPL': 'CPL ',
  'EUI': 'EUI ',
  'GPF': 'GPF ',
  'GBCS': 'GBCS ',
  'HCALCS': 'HCALCS ',
  'ALCS': 'ALCS ',
  'HAN': 'HAN ',
  'MPAN': 'MPAN ',
  'MPRN': 'MPRN ',
  'GHz': 'GHz ',
  'PIN': 'PIN ',
  'PPMID': 'PPMID ',
  'RMS': 'RMS ',
  'SMETS1': 'SMETS1 ',
  'SMETSCHTS': 'SMETS CHTS ',
  'SMI': 'SMI ',
  'TOU': 'TOU ',
  'UPRN': 'UPRN ',
  'UTRN': 'UTRN ',
  'UTC': 'UTC ',
  'SMWAN': 'SMWAN ',
  'WAN': 'WAN ',
  'DCC':'DCC ',
  'IDFor': 'ID For '
}

const duisRegex = new RegExp(Object.keys(duisTerms).join('|'), 'g');

// change e.g 'deviceType' to 'Device Type'
export function spaceCamel(string) {
  let modString = (string.includes('_')) ? (string.replace(/([-_]\w)/g, g => g[1].toUpperCase())) : string;
  modString = modString && (modString[0].toUpperCase() + modString.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2');
  if (modString.startsWith('CRQ') || modString.startsWith('CDA')) (modString = modString.substring(0, 7) + ' ' + modString.substring(7));
  return modString.replace(duisRegex, function (matched) {
    return duisTerms[matched]
  });
}

//applies custom validity to select dropdown fields with a generic default value
export function customValidityForDropdowns() {
  for (let selectBox of document.getElementsByClassName('jsx-parser')[0].getElementsByTagName('select')) {
    selectBox.setCustomValidity((selectBox.options[selectBox.selectedIndex].value === GENERIC_DEFAULT) ? SOME_ERROR : NO_ERROR);
  }
}

export function identifierType(identifier) {
  if (/[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}/.test(identifier)) {
    return DEVICE_ID
  }
  else if (/^\d{1,10}$/.test(identifier)) {
    return MPRN
  }
  else if (/^\d{13}$/.test(identifier)) {
    return MPAN
  }
  else {
    return INVALID_IDENTIFER
  }
}

export function isIdentifier(identifier) {
  return !(identifierType(identifier) === INVALID_IDENTIFER);
}

export function isMPxN(identifier) {
  return MPxNS.includes(identifierType(identifier));
}

export function specificOrDefaultIdentifier(identifier) {
  const idType = identifierType(identifier)
  return idType === INVALID_IDENTIFER ? DEVICE_ID : idType
}

