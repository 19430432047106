// where the constants need to be the same across more than one module

export const CRQ_FORM_FOCUS                           = "crq_form_focus";
export const FORCE_SIGNED_OUT_INACTIVE                = "Signed out due to inactivity.";
export const FORCE_SIGNED_OUT_COMMS_ERROR             = "Signed out due to a communications problem.";
export const UNKNOWN_DEVICE_TYPE                      = "unknown_device_type";
export const GENERIC_DEFAULT                          = "Please select...";
export const LOWER_RATE_CONSUMPTION_IN_PENCE_PER_KWH  = "lower_rate_consumption_in_pence_per_kwh"
export const HIGHER_RATE_CONSUMPTION_IN_PENCE_PER_KWH = "higher_rate_consumption_in_pence_per_kwh"
export const START_DATE_TIME                          = "start_date_time"
export const START_DATE                               = "start_date"
export const START_TIME                               = "start_time"
export const NO_ERROR                                 = ""
export const SOME_ERROR                               = " "
export const START                                    = "start"
export const END                                      = "end"
export const SINGLE_FIELD                             = "single_field"
export const CROSS_FIELD                              = "cross_field"
export const MPAN                                     = "MPAN"
export const MPRN                                     = "MPRN"
export const MPxNS                                    = [MPAN, MPRN]
export const DEVICE_ID                                = "Device ID"
export const DEVICE_ID_CAMEL                          = "deviceID"
export const MPXN_PRIMARY                             = "mPxNPrimary"
export const IDENTIFIERS                              = MPxNS.concat([DEVICE_ID_CAMEL, DEVICE_ID, MPXN_PRIMARY])
export const INVALID_IDENTIFER                        = "Invalid Identifier"
export const FILE_POSTFIX                             = '_file'
export const KEY_POSTFIX                              = '_key'
