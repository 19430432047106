import React, { useState, Fragment } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

export default function SmartOptional(props) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const wrappedComponents = React.Children.toArray(props.children);

  return (
    <Fragment>
      <Row className="align-items-start p-2">
        <Col sm="2">
          <Form.Label>{props.label}</Form.Label>
        </Col>
        <Col sm="1">
          <Form.Check
            type="checkbox"
            id="optionalCheckbox"
            name="optionalCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </Col>
        {isChecked && (
          <Col sm="9">
            {wrappedComponents.map((component, index) => (
              <Fragment key={index}>{component}</Fragment>
            ))}
          </Col>
        )}
      </Row>
    </Fragment>
  );
}