import React, {useState, useEffect} from "react";
import QRCode from 'qrcode.react';
import {
  getCurrentUser,
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup
} from 'aws-amplify/auth';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Loader} from "semantic-ui-react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const listStyle = {
  textAlign: "left",
  listStylePosition: "inside"
};

const QR_NOT_SET = 'QR_NOT_SET'

export default function SetupMFA({email}) {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [qrCode, setQrCode] = useState(QR_NOT_SET);

  const clientName = window.location.search.substr(1) !== "" ? window.location.search.substr(1) : window.location.hostname

  // on first render only
  useEffect( () => {
    async function fetchData () {
      const user = await getCurrentUser().catch((error) => setErrorMessage(error.message))
      const output = await setUpTOTP().catch((error) => setErrorMessage(error.message))
      const sharedSecret = output.sharedSecret;
      setQrCode("otpauth://totp/AWSCognito:" + clientName + "-" + email + "?secret=" + sharedSecret + "&issuer=AWSCognito")
    }
    fetchData()
  }, [])

  const clearValidated = () => {
    setValidated(false);
    setErrorMessage("")
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setValidated(true);
    } else {
      await verifyTOTPSetup({ code: event.target.totpCode.value}).catch((error) => setErrorMessage(error.message))
      await updateMFAPreference({ totp: 'ENABLED' }).catch((error) => setErrorMessage(error.message))
      clearValidated()
      window.location.reload();
    }
  }
  return (
    <div className="SetupMFA p-1">
      <Row>
        <Col/>
        <Col sm={6}>
          {qrCode !== '' && (
            <Form noValidate onSubmit={handleSubmit} className={validated ? "was-validated" : ""}>
              <hr/>
              <p>Please follow the steps below to set up 2-factor authentication.<br/>Check that your
                cursor is not covering any part of the bar code.</p>
              <hr/>
              <ol style={listStyle}>
                <li>Download Google Authenticator on your smart phone (available for both <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/gb/app/google-authenticator/id388497605">iPhone</a> and <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_GB">Android</a>).
                </li>
                <li>Open Google Authenticator on your smart phone.</li>
                <li>Press the "+" button.</li>
                <li>Press "Scan barcode".</li>
                <li>Scan the bar code on the screen with your smart phone.</li>
              </ol>
              <QRCode value={qrCode}/>
              <br/>
              <Form.Label>Enter the six-digit number displayed on your smart phone</Form.Label>
              <Form.Control
                autoFocus
                id="totpCode"
                type="password"
                required
                pattern="^[0-9]{6}$"
                placeholder={"Please enter your six-digit number here."}
                onChange={clearValidated}/>
              <Form.Control.Feedback type="invalid">Please enter a six-digit number.</Form.Control.Feedback>
              <br/>
              <div style={{display: "block"}}>
                <p style={{color: "red", fontSize: "11px"}}>{errorMessage}</p>
              </div>
              <br/>
              <div className="d-grid gap-2">
                <Button type="submit" title="Click here to login" >
                  Confirm
                </Button>
              </div>
            </Form>)}
          {qrCode === QR_NOT_SET && (
            <Loader active inline='centered'/>)}
        </Col>
        <Col/>
      </Row>
    </div>
  )
}
