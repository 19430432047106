import React, {useState} from 'react';
import {Form, Col, Row} from "react-bootstrap";
import SmartGeneral from "./SmartGeneral";

const MPAN = "mpan"
const MPRN = "mprn"
const UPRN = "uprn"
const PROPERTY_IDENTIFIER = "property_identifier"

export default function SmartPremisesIdentifier(props) {
  const [identifier, setIdentifier] = useState(MPAN);
  const handleChange = event => {
    setIdentifier(event.target.value);
  };
  return (
    <>
      <Row className="p-2">
        <Col sm="3">
          <Form.Label>{"Please select and input a premises identifier:"}
          </Form.Label>
        </Col>
        <Col xs="2" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={MPAN}
              onChange={handleChange}
              checked={identifier === MPAN}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">MPAN</Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="2" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={MPRN}
              onChange={handleChange}
              checked={identifier === MPRN}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">MPRN</Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="2" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={UPRN}
              onChange={handleChange}
              checked={identifier === UPRN}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">UPRN</Form.Check.Label>
          </Form.Check>
        </Col>
        {<Col xs="2" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={PROPERTY_IDENTIFIER}
              onChange={handleChange}
              checked={identifier === PROPERTY_IDENTIFIER}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">Property Identifier</Form.Check.Label>
          </Form.Check>
        </Col>}
      </Row>
      {identifier === MPAN &&
      <SmartGeneral
        name={MPAN}
        label="MPAN"
        placeholder="Enter the MPAN"
        required
        minlength="13"
        maxlength="13"
        size="13"
        pattern="^[ _\-\.0-9a-zA-Z]{13,13}$"
        title="Enter the MPAN"
        feedback="Enter 13 characters using a-z, A-Z, 0-9 and . - _"
      />}
      {identifier === MPRN &&
      <SmartGeneral
        name={MPRN}
        label="MPRN"
        placeholder="Enter the MPRN"
        required
        maxLength="10"
        minLength="1"
        pattern="^[ _\-\.0-9a-zA-Z]{1,10}$"
        title="Enter the MPRN"
        feedback="Enter between 1 and 10 characters using a-z, A-Z, 0-9 and . - _"
      />}
      {identifier === UPRN &&
      <SmartGeneral
        label="UPRN"
        name="uprn"
        placeholder="Enter the UPRN"
        required
        maxlength="12"
        size="12"
        pattern="^[0-9]{0,12}$"
        title="Enter the UPRN"
        feedback="Enter up to 12 characters using 0-9"
      />}
      {identifier === PROPERTY_IDENTIFIER &&
      <>
        <SmartGeneral
          label="Post Code"
          name="post_code"
          placeholder="Enter the Post Code"
          required
          minLength="6"
          maxLength="8"
          pattern="^[ 0-9a-zA-Z]{6,8}$"
          title="Enter the Post Code"
          feedback="Enter between 6 and 8 characters using a-z, A-Z, 0-9 and spaces"
        />
        <SmartGeneral
          label="Building name or number"
          name="address_identifier"
          placeholder="Enter the building name or number"
          maxLength="30"
          pattern="^[, _\-\.0-9a-zA-Z]{0,30}$"
          title="Enter the building name or number"
          feedback="Enter up to 30 characters using a-z, A-Z, 0-9 and . - _"
        />
      </>
      }
    </>
  )
}
