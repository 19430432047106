import React, {Fragment, useState} from "react";
import ChangePassword from './ChangePassword';
import LoginWithTOTP from './LoginWithTOTP';
import ForgottenPassword from './ForgottenPassword'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {signIn} from 'aws-amplify/auth';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "../components/Link";

// define steps:
const LOGIN = 'LOGIN'
const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD'
const CHANGE_PASSWORD = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
const LOGIN_WITH_TOTP = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'

const NO_ERRORS = ''

export default function Login() {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(NO_ERRORS);
  const [nextStep, setNextStep] = useState(LOGIN);

  const clearValidated = () => {
    setValidated(false);
    setErrorMessage(NO_ERRORS)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setValidated(true);
    } else {
        try {
          const user = await signIn({
            username: event.target.email.value,
            password: event.target.password.value
          });

          if (user) {
            setNextStep(user.nextStep.signInStep);
          }
        } catch (error) {
          setErrorMessage(error.message);
      }
    }
  }

  return (
      <Fragment>
        {nextStep === LOGIN && (
          <Row>
            <Col/>
            <Col>
              < Form noValidate onSubmit={handleSubmit} className={validated ? "was-validated" : ""}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    autoFocus
                    required
                    id="email"
                    type="email"
                    autoComplete="email"
                    onChange={clearValidated}/>
                  <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    id="password"
                    required
                    autoComplete="current-password"
                    onChange={clearValidated}/>
                  <br/>
                  <div style={{display: "block"}}>
                    <p style={{color: "red", fontSize: "11px"}}>{errorMessage}</p>
                  </div>
                  <br/>
                  <div className="d-grid gap-2">
                    <Button type="submit" title="Click here to login" >
                      Login
                    </Button>
                  </div>
                  <br/>
                  <Link
                    title="Click here if you have forgotten your password"
                    onClick={() => setNextStep(FORGOTTEN_PASSWORD)}
                    value="Forgotten password?"/>
                </Form.Group>
              </Form>
            </Col>
            <Col/>
          </Row>
        )}
        {nextStep === CHANGE_PASSWORD && (
          <ChangePassword />
        )}
        {nextStep === LOGIN_WITH_TOTP && (
          <LoginWithTOTP />
        )}
        {nextStep === FORGOTTEN_PASSWORD && (
          <ForgottenPassword />
        )}
      </Fragment>
  )
}