import React, {Fragment} from 'react';
import SmartSwitchedOnPeriod from "./SmartSwitchedOnPeriod";

export default function SmartDynamicSwitching(props) {
  return (
    [1, 2, 3, 4, 5].map(period_index => {
        return (
          <Fragment>
            <SmartSwitchedOnPeriod
              period_index={period_index}
              minDateTime={props.minDateTime}
              maxDateTime={props.maxDateTime}
            />
          </Fragment>
        )
      }
    )
  )
}
