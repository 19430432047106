import React from 'react';
import SmartTable from "../common/components/SmartTable";

// display a list of details from the s3 list of client interaction object's keys plus lastModified date-time
export default function History({columns, data, maxHistoryHeight}) {
  // allow the table to scroll and only take up 40% of the view port
  return (
    <div style={{overflow: 'auto', maxHeight: (maxHistoryHeight - 35)}}>
      <SmartTable columns={columns} data={data} />
    </div>
  )
}
