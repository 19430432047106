import React, {useEffect, useState} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import moment from 'moment';
import {CognitoIdentityClient} from "@aws-sdk/client-cognito-identity";
import {fromCognitoIdentityPool} from "@aws-sdk/credential-provider-cognito-identity";
import {CloudWatchClient, GetMetricDataCommand} from "@aws-sdk/client-cloudwatch";
import amplifyConfigs from '../index.jsx';

const Graph = ({authSession, clientName, config}) => {
  const [renderedGraphs, setRenderedGraphs] = useState([]);

  const fetchGraphData = async (graphConfig, cloudWatchClient) => {
    // Set StartTime and EndTime for each graph
    graphConfig.StartTime = new Date(Date.now() - (1 * 24 * 60 * 60 * 1000));
    graphConfig.EndTime = new Date(Date.now());
    graphConfig.ScanBy = "TimestampAscending";

    // create hash for Cloudwatch call without colours and title key/value pairs
    const cloudwatchConfig = {
      ...graphConfig,
      MetricDataQueries: graphConfig.MetricDataQueries.map(({Colour, Title, ...otherConfig}) => otherConfig),
    };

    // Fetch metric data from CloudWatch and create promise so data is returned before continuing
    try {
      const command = new GetMetricDataCommand(cloudwatchConfig);
      const result = await cloudWatchClient.send(command);

      // update graphConfig object with the values obtained from the API call
      graphConfig.graphValues = [];
      result.MetricDataResults.forEach((metricData, index) => {
        metricData.Values.forEach((value, i) => {
          if (!graphConfig.graphValues[i]) {
            // Initialize the data point with the first metric data
            graphConfig.graphValues[i] = {
              time: metricData.Timestamps[i],
            };
          }
          graphConfig.graphValues[i][metricData.Label] = value;
        });
      });
      renderGraph(graphConfig);
    } catch (err) {
      console.error('Error fetching metric data:', err);
    }
  };

  // Create the rendered graph element
  const renderGraph = (graphDatum) => {
    const graphTitle = graphDatum.MetricDataQueries[0].Title;
    let labelColourHash = {};
    graphDatum.MetricDataQueries.forEach((lineLabel) => {
      labelColourHash[lineLabel.Label] = lineLabel.Colour;
    });

    const renderedGraph = (
      <div key={graphTitle} className="graph-container">
        <h5>{graphTitle}</h5>
        <ResponsiveContainer className="responsive-container">
          <LineChart data={graphDatum.graphValues}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="time" tickFormatter={(time) => moment(time).format('HH')} minTickGap={75}/>
            <YAxis/>
            <Tooltip labelFormatter={(time) => moment(time).format()}/>
            <Legend className="recharts-default-legend"/>
            {Object.keys(labelColourHash).map((label, index) => (
              <Line
                key={`line-${index}`}
                type="monotone"
                dataKey={label}
                stroke={labelColourHash[label]}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );

    // Append the rendered graph element to the renderedGraphs state array
    setRenderedGraphs((prevGraphs) => [...prevGraphs, renderedGraph]);
  };

  useEffect(() => {
    // Create CloudWatch client with user credentials from Cognito
    const getCloudWatchClient = async () => {
      const getCredentials = fromCognitoIdentityPool({
        client: new CognitoIdentityClient({region: 'eu-west-2'}),
        identityPoolId: amplifyConfigs[clientName].identityPoolId,
        logins: {
          [`cognito-idp.eu-west-2.amazonaws.com/${amplifyConfigs[clientName].userPoolId}`]: authSession.tokens.idToken.toString(),
        },
      });
      const credentials = await getCredentials();

      return new CloudWatchClient({
        region: 'eu-west-2',
        credentials: credentials,
      });
    };

    const setupAndFetchGraphData = async () => {
      try {
        const cloudWatchClient = await getCloudWatchClient();
        config.graphs_config.forEach((graphConfig) => {
          // Fetch graph data for each graph config
          fetchGraphData(graphConfig, cloudWatchClient);
        });
      } catch (error) {
        console.error('Error setting up CloudWatch client:', error);
      }
    };

    setupAndFetchGraphData();
  }, []);

  // Return the renderedGraphs array to display the graphs
  return <div className="graphs-container">{renderedGraphs}</div>;
}

export default Graph;