import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {GENERIC_DEFAULT, CRQ_FORM_FOCUS} from '../../common/constants';
import {customValidityForDropdowns} from "../../common/commonUtilities";

// Fragment for select variables ensuring consistent formatting and adding a placeholder that cannot be selected
export default function SmartSelect(props) {
  const defaultValue = ("default" in props) ? props.default : GENERIC_DEFAULT
  const defaultNotSet = (defaultValue === GENERIC_DEFAULT)
  const optionsArray = props.optionsList.split(",\n")
  const singleItemDropdown = !defaultNotSet && optionsArray.length === 1

  return (
    <Form.Group as={Row} className="p-2">
      <Form.Label column sm="3">{props.label}</Form.Label>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
        <Form.Select
          name={props.name}
          id={props.name}
          required
          disabled={singleItemDropdown}
          title={props.title}
          defaultValue={defaultValue}
          onChange={defaultNotSet ? customValidityForDropdowns : undefined}>
          {defaultNotSet && <option value={defaultValue} disabled>{defaultValue}</option>}
          {optionsArray.map((key, index) => {
            return (<option key={index}>{key}</option>)
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">Select a valid value</Form.Control.Feedback>
        {singleItemDropdown &&
        <Form.Control
          id={props.name}
          name={props.name}
          value={defaultValue}
          plaintext
          readOnly
          hidden
        />}
      </Col>
    </Form.Group>
  )
}
