import React, {useState} from "react";
import {confirmSignIn} from 'aws-amplify/auth';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {PASSWORD_REQUIREMENTS} from '../commonUtilities';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ChangePassword() {
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const clearValidated = () => {
    setValidated(false);
    setErrorMessage("")
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setValidated(true);
    } else if (event.target.newPassword.value !== event.target.confirmNewPassword.value) {
      setErrorMessage("Passwords do not match.");
    } else {
      try {
        await confirmSignIn({ challengeResponse: event.target.newPassword.value})
      } catch (error) {
        if (error.code === "InvalidParameterException" || error.code === "InvalidPasswordException") {
          setErrorMessage(PASSWORD_REQUIREMENTS)
        } else {
          setErrorMessage(error.message)
        }
      };
    }
  }

  return (
    <div>
      <Row>
        <Col/><Col>
        < Form noValidate onSubmit={handleSubmit} className={validated ? "was-validated" : ""}>
          <Form.Label>New password</Form.Label>
          <Form.Control
            autoFocus
            required
            id="newPassword"
            type="password"
            autoComplete="new-password"
            onChange={clearValidated}/>
          <Form.Label>Confirm new password</Form.Label>
          <Form.Control
            type="password"
            id="confirmNewPassword"
            autoComplete="new-password"
            required
            placeholder="Re-type your new password"
            onChange={clearValidated}/>
          <br/>
          <div style={{display: "block"}}>
            <p style={{color: "red", fontSize: "11px"}}>{errorMessage}</p>
          </div>
          <br/>
          <div className="d-grid gap-2">
            <Button type="submit">
              Change password
            </Button>
          </div>
          <br/>
        </Form>
      </Col><Col/>
      </Row>
    </div>
  )
}
