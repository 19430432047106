import React from 'react';
import {Button} from "react-bootstrap";

// displays a hyperlink
export default function Link({title, onClick, value, deviceType = ""}) {
  return (
    <Button
      className={deviceType ? `btn btn-${deviceType.toUpperCase()}` : "btn btn-link"}
      size="sm"
      style={{margin: "1px", padding: "1px"}}
      title={title}
      onClick={onClick}
    >{value}</Button>
  );
}