import React, {useState, useEffect, useRef} from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import {Icon} from "semantic-ui-react";
import {specificOrDefaultIdentifier} from "../common/commonUtilities";

export default function Device({deviceId, setDeviceId, handleDeviceChange}) {
  const [validated, setValidated] = useState(false)
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  const handleInputChange = (event) => {
    const isInputValid = event.target.checkValidity();
    setShowPopover(!isInputValid);
    handleDeviceChange(event);
  };

  // check validity and set the form to display popover errors if there are any.
  // Otherwise, call the parent's document.getElementById("device-id").value function with the details from the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = event.target.checkValidity();
    setValidated(true);
    setShowPopover(!isFormValid);
    if (isFormValid) {
      setDeviceId(document.getElementById("device-id").value.toUpperCase());
      setValidated(false);
    }
  }

  useEffect(() => {
    const input = document.getElementById("device-id");
    if (input) {
      const isInputValid = input.checkValidity();
      setShowPopover(!isInputValid && input.value !== '');
    }
  }, [deviceId]);


  return (
    < Form noValidate onSubmit={handleSubmit} className={validated ? "was-validated" : ""}>
      <InputGroup>
        <Form.Label>{specificOrDefaultIdentifier(document.getElementById("device-id") ? document.getElementById("device-id").value.toUpperCase() : "")}</Form.Label>
        <InputGroup>
          <Form.Control
            ref={target}
            type="text"
            name="device-id"
            id="device-id"
            required
            onInput={handleInputChange}
            maxLength="23"
            size="23"
            pattern="^(?!70-[Bb]3-[Dd]5-1[Ff]-3)[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}|^\d{1,10}$|^\d{13}$"
            placeholder="Enter Device ID or MPxN"
            title="Enter Device ID or MPxN"/>
          <Overlay
            show={showPopover}
            target={target.current}
            placement="right"
            containerPadding={20}>
            <Popover id="popover-invalid-feedback">
              <Popover.Body>
                Please enter:
                <ul>
                  <li>a non DCC-reserved device ID GUID in the format Aa-22-33-44-55-66-77-88 using a-f, A-F and 0-9</li>
                  <li>a valid 13 digit MPAN</li>
                  <li>a valid 1-10 digit MPRN</li>
                </ul>
              </Popover.Body>
            </Popover>
          </Overlay>
          <div className="d-grid">
            <Button type="submit">
              <Icon name="refresh"/>
            </Button>
          </div>
        </InputGroup>
      </InputGroup>
    </Form>
  )
}