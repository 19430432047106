import React, {useState} from "react";
import {confirmSignIn} from 'aws-amplify/auth';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const NO_ERRORS = ''

export default function LoginWithTOTP() {

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(NO_ERRORS);

  const clearValidated = () => {
    setValidated(false);
    setErrorMessage(NO_ERRORS)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setValidated(true);
    } else {
      confirmSignIn({ challengeResponse: event.target.passCode.value }
      ).catch((error) => setErrorMessage(error.message))
    }
  }

  return (
    <div className="TOTPPassword p-1">
      <Row>
        <Col/>
        <Col sm={4}>
          <Form noValidate onSubmit={handleSubmit} className={validated ? "was-validated" : ""}>
            <Form.Label>Enter the six-digit number displayed in Google Authenticator for AWS Cognito</Form.Label>
            <Form.Control
              autoFocus
              id="passCode"
              type="password"
              required
              autoComplete="one-time-code"
              onChange={clearValidated}
              pattern="^[0-9]{6}$"
            />
            <Form.Control.Feedback type="invalid">Please enter a six-digit number only.</Form.Control.Feedback>
            <br/>
            <div style={{display: "block"}}>
              <p style={{color: "red", fontSize: "11px"}}>{errorMessage}</p>
            </div>
            <br/>
            <div className="d-grid gap-2">
              <Button type="submit">
                Confirm
              </Button>
            </div>
          </Form>
        </Col>
        <Col/>
      </Row>
    </div>
  )
}
