import {downloadData} from 'aws-amplify/storage';
import FileSaver from "file-saver";
import {reverseTimestampRemoved} from "./commonUtilities";

export async function fileDownload(value, clientName, setShowFileNotFound) {
  if (value.includes("/")) {
    try {
      const {body, contentType} = await downloadData({key: value, options: {accessLevel: 'guest'}}).result;
      const fileData = new Blob([await body.blob()], {type: contentType});
      FileSaver.saveAs(fileData, reverseTimestampRemoved(value.split('/').slice(-1)[0]));
      const cameFromEmail = window.location.search.substr(1);
      if ((cameFromEmail !== "") && (cameFromEmail !== clientName)) {
        window.location.replace(window.location.origin)
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      setShowFileNotFound(error);
    }
  }
}

// @param the s3Key [String] within the client bucket, including any .json extension
// the client bucket is named using the clientID in lower case
// @return [String] being the parse json from the file or an empty string if nothing valid
export async function jsonFromStorage(s3key) {
  try {
    const downloadTask = await downloadData({key: s3key, options: {accessLevel: 'initialAccess'}})

    const downloadResult = await downloadTask.result;
    if (downloadResult && downloadResult.body) {
      const json_string = await downloadResult.body.text();
      return JSON.parse(json_string);
    } else {
      return "";
    }
  } catch (error) {
    console.error('Error downloading data:', error);
    return "";
  }
}
