import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CRQ_FORM_FOCUS} from "../../common/constants";


//Fragment to handle email input
export default function SmartEmail(props) {
  return (
    <Form.Group as={Row} className="p-2">
      <Form.Label column sm="3">{props.label}</Form.Label>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
        <Form.Control
          type="email"
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          required={props.required}
          title={props.title}/>
        <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}