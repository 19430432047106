import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const SIGNED_OUT_BUTTON = "signed-out-button"

// display a signed out warning
export default function SignedOut(props) {
  return (
    <Modal
      show={props.show}
      onEntered={() => document.getElementById(SIGNED_OUT_BUTTON).focus()}>
      <Modal.Body>{props.signOutMessage}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
            <Button
                onClick={() => {
                    props.clearSignedOut();
                    window.location.reload();
                }}
                variant={"primary"}
                id={SIGNED_OUT_BUTTON}
            >
            OK
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
