import React, {Fragment, useState} from 'react';
import SmartFile from './SmartFile'
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import SmartSelect from "./SmartSelect";
import {CRQ_FORM_FOCUS} from "../../common/constants";

// Fragment for select variables ensuring consistent formatting and adding a placeholder that cannot be selected
export default function SmartADTFile(props) {
  const [checked, setChecked] = useState(true)

  const toggleCheckBox = () => {
    setChecked(!checked)
  }

  return (
    <Fragment>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
      <Form.Check
        type="checkbox"
        label="Use latest suggested ADT file?"
        id="checkbox"
        name="use_latest_suggested_adt_file"
        value={checked}
        checked={checked}
        onChange={toggleCheckBox}
      />
        </Col>
      {checked && props.optionsList.length > 0 &&
        <SmartSelect
          label="DCC User ID"
          name="dcc_user_id"
          title="Select a DCC User ID"
          optionsList={props.optionsList}
        />
        }
        {!checked &&
          <div>
            <SmartFile label="Select a ADT file to upload." name="object_location"/>
          <SmartSelect
          label="Bypass validation for this ADT file? (Only recommended after changes to ADT files have been agreed)"
          name="bypass_validation"
          title="Bypass validation for this ADT file"
          optionsList={"true,\nfalse"}
          default="false"
          />
          </div>
          }
    </Fragment>
  )
}
