import React, {Fragment, useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CRQ_FORM_FOCUS} from "../../common/constants";

export default function SmartSevenDayTwoRatePeriodOfDay(props) {
  const NO_ERROR = ""
  const SOME_ERROR = " "
  const CUSTOM_PERIOD_LABEL = "Custom Period"
  const UTC = "UTC"
  const BST = "BST"
  // names are also used as ids on the elements
  const startTimeName  = props.defaultStartTimeName || props.startTime;
  const endTimeName    = props.defaultEndTimeName || props.endTime;
  const periodNameName = props.defaultPeriodNameName || props.periodName;
  const utcOrBstName   = props.defaultUtcOrBstName || props.utcOrBst;
  const mandatory      = String(props.mandatory) === 'true'
  const utcOnly        = String(props.utcOnly) === 'true'

  // state variables
  const [inputs, setInputs] = useState({
    [startTimeName]: props.defaultStartTime,
    [endTimeName]: props.defaultEndTime,
    [periodNameName]: CUSTOM_PERIOD_LABEL,
    [utcOrBstName]: UTC
  })
  const [errorMessage, setErrorMessage] = useState(NO_ERROR);
  const [checked, setChecked] = useState(false);

  // do error checks whenever a time changes
  useEffect(() => {
    let error = NO_ERROR;
    if (inputs[startTimeName] === inputs[endTimeName]) {
      error = "Start and end times cannot be the same"
    }
    if (document.getElementById(startTimeName)) {
      document.getElementById(startTimeName).setCustomValidity((error === NO_ERROR) ? NO_ERROR : SOME_ERROR);
      document.getElementById(endTimeName).setCustomValidity((error === NO_ERROR) ? NO_ERROR : SOME_ERROR);
    }
    setErrorMessage(error);
  }, [inputs])

  // update the state value when any input is updated
  const handleInputChange = (e) => {
    setInputs({
      ...inputs,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  const toggleCheckBox = (e) => {
    setChecked(!checked)
  }

  const periodNameList = props.periodNameOptions.length === 0 ? CUSTOM_PERIOD_LABEL : CUSTOM_PERIOD_LABEL + "," + props.periodNameOptions

  return (
    <Fragment>
      {!mandatory && <Col sm="5" name={CRQ_FORM_FOCUS}>
        <Form.Check
          type="checkbox"
          label={`Set a time period on Switch ${props.switch_index}?`}
          id={periodNameName}
          name={periodNameName}
          checked={checked}
          onChange={toggleCheckBox}
        />
      </Col>}
      {(mandatory || checked) && <Col>
        <Form.Group as={Row} className="p-2">
          <Form.Label column sm="3">Select a pre-defined period OR set a custom period{props.switch_index ? ` for switch ${props.switch_index}` : ''}:</Form.Label>
          <Col sm="9" name={CRQ_FORM_FOCUS}>
            <Form.Select
              name={periodNameName}
              id={periodNameName}
              required
              onChange={handleInputChange}
              defaultValue={CUSTOM_PERIOD_LABEL}>
              {periodNameList.split(",").map((key, index) => {
                return (<option key={index}>{key}</option>)
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Select a valid value</Form.Control.Feedback>
          </Col>
        </Form.Group>
        {
          (inputs[periodNameName] === CUSTOM_PERIOD_LABEL) && <Form.Group as={Row} className="p-2">
            <Col sm="3">
              <Form.Label>{props.label}</Form.Label>
            </Col>
            {<Col sm="4" name={CRQ_FORM_FOCUS}>
              <Form.Label>Start time:</Form.Label>
              <Form.Control
                type="time"
                name={startTimeName}
                id={startTimeName}
                value={inputs[startTimeName]}
                required
                onChange={handleInputChange}/>
              <Form.Control.Feedback type="invalid">Time is not valid</Form.Control.Feedback>
            </Col>}
            {<Col sm="4">
              <Form.Label>End time:</Form.Label>
              <Form.Control
                type="time"
                name={endTimeName}
                id={endTimeName}
                value={inputs[endTimeName]}
                required
                onChange={handleInputChange}/>
              <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
            </Col>}
          </Form.Group>
        }
        {
          (inputs[periodNameName] === CUSTOM_PERIOD_LABEL) && <Form.Group as={Row} className="p-2">
            <Form.Label column sm="3">Keep in UTC or adjust for BST?</Form.Label>
            <Col sm="9" name={CRQ_FORM_FOCUS}>
              <Form.Select
                name={utcOrBstName}
                id={utcOrBstName}
                required
                title={utcOrBstName}
                defaultValue={UTC}
                onChange={handleInputChange}>
                {(utcOnly ? [UTC] : [UTC, BST]).map((key, index) => {
                  return (<option key={index}>{key}</option>)
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Select a valid value</Form.Control.Feedback>
            </Col>
          </Form.Group>
        }
      </Col>}
    </Fragment>
  )
}