import React, { memo, useMemo } from "react";
import {useTable, useSortBy, useFilters} from "react-table";
import Table from 'react-bootstrap/Table';
import { matchSorter } from 'match-sorter'
import Form from "react-bootstrap/Form";

const no_scroll_style = {
  position: 'sticky',
  top: "-1px",
  background: 'white',
  whiteSpace: 'noWrap',
  verticalAlign: 'text-top'
}

// Define a default UI for filtering
function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter}}) {
  const count = preFilteredRows.length
  return (
    <Form.Control
      value={filterValue || ''}
      width={"100%"}
      size='sm'
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} ...`}/>
  )
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  // Render a multi-select box
  return (
    <Form.Select
      size='sm'
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Form.Select>
  )
}


function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {keys: [row => row.values[id]]})
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val


export default memo(function SmartTable({columns, data}) {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy
  );

  /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <Table striped bordered hover size="sm" {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr style={{verticalAlign: 'top'}} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th style={no_scroll_style} width={column.width} {...column.getHeaderProps()}>
              <div>
                {!column.canSort && column.render("Header")}
                {column.canSort &&
                <span style={{whiteSpace: 'noWrap'}} {...column.getSortByToggleProps()}>
              {column.render("Header")}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <span className="emoji"> 🔽</span>
                      : <span className="emoji"> 🔼</span>
                    : <span className="emoji"> 🔼🔽</span>}
              </span>}
                <br/>
              </div>
              {/* Render the columns filter UI */}
              <div>{column.canFilter ? column.render('Filter') : <Form.Control readOnly size='sm' disabled/>}</div>
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map(cell => {
              return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
            })}
          </tr>
        );
      })}
      </tbody>
    </Table>
  );
})
