import React, {Fragment, useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { revalidateElements } from "./SmartLogPeriod.jsx"
import {
  CRQ_FORM_FOCUS,
  NO_ERROR,
  START_DATE,
  SINGLE_FIELD,
  CROSS_FIELD,
  START,
  END
} from "../../common/constants";

const NO_FOCUS_HERE = "no_focus_here"
const HIDDEN = "hidden"
const NOT_HIDDEN = ""

export default function SmartDateTime(props) {

  // names are also used as ids on the elements
  const dateTimeName = props.name + "_date_time";
  const dateName = props.name + "_date";
  const timeName = props.name + "_time";
  const selectedName = props.name + "_selected";

  const defaultDateTime = (props.dateTimeDefault || moment.utc());

  const partOfSmartLogPeriod = (props.name === START) || (props.name === END)

  // state variables
  const [dateTime, setDateTime] = useState(defaultDateTime.format("YYYY-MM-DDTHH:mm:00") + "Z");
  const [inputs, setInputs] = useState({
    [dateName]: defaultDateTime.format('YYYY-MM-DD'),
    [timeName]: defaultDateTime.format('HH:mm'),
    [selectedName]: !props.optional
  })
  const [errorMessage, setErrorMessage] = useState(NO_ERROR);

  // update dateTime whenever date or time change
  useEffect(() => updateDateTime(), [inputs])

  // update the state value when any input is updated
  const handleInputChange = (e) => {
    const value = (e.currentTarget.type === "checkbox") ? e.currentTarget.checked : e.currentTarget.value
    setInputs({
      ...inputs,
      [e.currentTarget.name]: value
    })
  }

  const updateDateTime = () => {
    const momentDateTime = moment.utc(inputs[dateName] + "T" + inputs[timeName], "YYYY-MM-DDTHH:mm:00");
    const dateTimeCorrectFormat = momentDateTime.format("YYYY-MM-DDTHH:mm:00") + "Z"
    let error = NO_ERROR;
    if (props.minDateTime && momentDateTime <= moment.utc(props.minDateTime)) {
      error = "Date-time must be later";
      // don't set setDateTimeErrorType unless field part os a SmartLogPeriod
      partOfSmartLogPeriod && props.setDateTimeErrorType(SINGLE_FIELD)
    } else if (props.maxDateTime && momentDateTime >= moment.utc(props.maxDateTime)) {
      error = "Date-time must be earlier";
      partOfSmartLogPeriod && props.setDateTimeErrorType(SINGLE_FIELD)
    } else {
      partOfSmartLogPeriod && props.setDateTimeErrorType(CROSS_FIELD)
    }
    setDateTime(dateTimeCorrectFormat);
    if (partOfSmartLogPeriod) props.setDateTimeValue(momentDateTime)
    updateError(error)
  }

  const updateError = (error) => {
    if (inputs[selectedName]) {
      revalidateElements(error, dateName, timeName)
    }
    setErrorMessage(error);
  }

  return (
    <Fragment>
      <Form.Group as={Row} hidden={HIDDEN}>
        <Form.Control
          name={dateTimeName}
          id={dateTimeName}
          value={dateTime}
          plaintext
          readOnly/>
      </Form.Group>
      <Row hidden={props.hidden || NOT_HIDDEN} as={Row} className="p-2">
        <Col sm="2">
          <Form.Label>{props.label}</Form.Label>
        </Col>
        <Col sm="1" name={props.optional ? CRQ_FORM_FOCUS : NO_FOCUS_HERE}>
          <Form.Check
            type="checkbox"
            id={selectedName}
            name={selectedName}
            checked={inputs[selectedName]}
            disabled={!props.optional}
            onChange={handleInputChange}
          />
        </Col>
        {inputs[selectedName] && <Col sm="5" name={!props.optional ? CRQ_FORM_FOCUS : NO_FOCUS_HERE}>
          <Form.Control
            type="date"
            name={dateName}
            id={dateName}
            value={inputs[dateName]}
            required
            onChange={handleInputChange}/>
          <Form.Control.Feedback type="invalid">Date-time is not valid</Form.Control.Feedback>
        </Col>}
        {inputs[selectedName] && <Col sm="4">
          <Form.Control
            type="time"
            name={timeName}
            id={timeName}
            value={inputs[timeName]}
            required
            onChange={handleInputChange}/>
          <Form.Control.Feedback type="invalid">
            {(dateName === START_DATE && props.dateTimeErrorType === CROSS_FIELD && "UTC Start must be before UTC End") || errorMessage}
          </Form.Control.Feedback>
        </Col>}
      </Row>
    </Fragment>
  )
}
