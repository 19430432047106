import React, {Fragment, useState} from 'react';
import {resetFocus} from "../common/commonUtilities";
import Link from "../common/components/Link";
import ExtraInfo from "./ExtraInfo";

// displays a hyperlink for any extra info, when clicked, causes all the extra info to display
export default function ExtraInfoLink({title, content}) {
  const [showExtraInfo, setShowExtraInfo] = useState(false);

  const handleExtraInfoClose = () => {
    resetFocus();
    setShowExtraInfo(false);
  }

  const handleExtraInfoOpen = () => {
    setShowExtraInfo(true);
  }

  return (
    <Fragment>
      <Link
        title="Click to show information"
        onClick={handleExtraInfoOpen}
        value={title}/>
      {showExtraInfo && <ExtraInfo
        title={title}
        content={content}
        show={showExtraInfo}
        onHide={handleExtraInfoClose}/>}
    </Fragment>
  );
}
