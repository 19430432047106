import React, {Fragment} from 'react';
import SmartSevenDayTwoRatePeriodOfDay from "./SmartSevenDayTwoRatePeriodOfDay";

const alcsSwitches = [1, 2, 3, 4, 5]
export default function SmartSevenDayTwoRateSwitching(props) {
  return (
    alcsSwitches.map(switch_index => {
        return (
          <Fragment>
            <SmartSevenDayTwoRatePeriodOfDay
              defaultPeriodNameName={`period_name_switch_${switch_index}`}
              defaultUtcOrBstName={`utc_or_bst_switch_${switch_index}`}
              switch_index={switch_index}
              defaultStartTimeName={`start_time_switch_${switch_index}`}
              defaultStartTime={props.defaultStartTime}
              defaultEndTimeName={`end_time_switch_${switch_index}`}
              defaultEndTime={props.defaultEndTime}
              periodNameOptions={props.periodNameOptions}
              mandatory={switch_index === 1}
              utcOnly={props.utcOnly}
            />
          </Fragment>
        )
      }
    )
  )
}
