import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {resetFocus} from "../common/commonUtilities";
import {SUBMITTED_BUTTON} from "../common/commonUtilities"
import Linkify from "linkify-react";

// display the CRQ submission response from the gateway
export default function Submitted({show, response, clearSubmitted, delayedHistoryUpdate} ) {

  const successful = response? (response.includes("_ack") || response.includes("Ack")): false;
  const errored_out = response ? response.includes("ERROR") : true;
  const title = (successful && "Acknowledgement of submitted request:") ||
    (errored_out && "Unexpected error. Please contact support and include a copy of the error displayed below:") || "Problem with submitted request:";

  return (
    <Modal
      size='lg'
      show={show}
      onExit={clearSubmitted}
      onEnter={delayedHistoryUpdate}
      onEntered={resetFocus}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre style={{
          overflow: 'scroll',
          maxHeight: '40vh',
          whiteSpace: 'pre-wrap'
        }}>
          <Linkify options={{
            target: "_blank", rel: "noopener noreferrer", validate: {
              url: (value) => /^https:\/\//.test(value)
            }
          }}>
            {response}
          </Linkify>
        </pre>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button onClick={clearSubmitted} variant={successful ? "primary" : "warning"} id={SUBMITTED_BUTTON}>
            OK
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
