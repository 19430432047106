import React from 'react';
import Link from "../common/components/Link";

// displays a hyperlink for a Device ID, when clicked, causes all the Device's details to display
export default function DeviceIdLink({deviceId, setDeviceId, setDeviceLoaded, deviceType=""}) {
  const deviceIdentifier = deviceId.toUpperCase()
  return (
    <Link
      title="Click to select Device"
      onClick={() => {
        document.getElementById("device-id").value = deviceIdentifier;
        setDeviceId(deviceIdentifier)
        setDeviceLoaded(false) }}
      key={deviceIdentifier}
      value={deviceIdentifier}
      deviceType={deviceType}
    />
  )
}
