import Spinner from "react-bootstrap/Spinner";
import React from "react";

export default function LoadingOverlay ({active, children}) {
  return (
    <div className="loading-overlay-wrapper">
      {children}
      {active && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="primary" role="status"/>
        </div>
        )}
    </div>
  );
};
