import React, {useState, useEffect} from 'react';

//application specific
import './SmartAccess.css';
import Login from './common/login/Login';
import SetupMFA from './common/login/SetupMFA';
import SmartPearFooter from "./common/components/SmartPearFooter";
import Gateway from "./gateway/Gateway";
import NoClientAccess from "./common/components/NoClientAccess";
import {fileDownload} from './common/fileDownload';
import Repository from "./repository/Repository";

//aws cognito
import { Hub } from 'aws-amplify/utils';
import {
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  signOut
} from 'aws-amplify/auth'

// Bootstrap UI components
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


// Semantic UI components
import {Loader} from 'semantic-ui-react'

// the alert to show the user has been signed out
import SignedOut from './common/login/SignedOut';
import InactivityTimer from "./common/components/InactivityTimer";
import {FORCE_SIGNED_OUT_COMMS_ERROR, FORCE_SIGNED_OUT_INACTIVE} from "./common/constants";
import SmartPearHeader from "./common/components/SmartPearHeader";
import SmartPearBrandFooter from "./common/components/SmartPearBrandFooter";
import {jsonFromStorage} from "./common/fileDownload";
import FileNotFound from "./gateway/FileNotFound";
import ForgottenPassword from "./common/login/ForgottenPassword";
import Graph from './graph/graph.jsx';

//authentication statuses
const LOADING = "nothing set yet"
const SIGN_IN = "user needs to sign in"
const NO_CLIENT = "the URL doesn't identify a valid client"
const SIGNED_IN = "The user is currently authenticated"
const USER_SIGNED_OUT = "Successfully signed out."
const CHANGE_PASSWORD = "Change password"

// the container handling:
// - user sign in / out etc
// - headers / footers and branding
// - tabs containing services the client has access to
export default function SmartAccess({clientName, clientId}) {

  const [userAuth, setUserAuth] = useState({
    status: LOADING,
    userMFA: undefined,
    authSession: undefined,
    userAttributes: undefined
  });
  const [gatewayFound, setGatewayFound] = useState(false);
  const [showFileNotFound, setShowFileNotFound] = useState(false);
  const [downloadAttempted, setDownloadAttempted] = useState(false);
  const [configFound, setConfigFound] = useState(false);
  const [config, setConfig] = useState({
    allowed_folders: [],
    client_name: "",
    gateway_url: "",
    gateway_access: false,
    repository_access: false
  });

// for first render only, so [] as the inputs
  useEffect(() => {
    if (clientId) {
      // register the handleAuth function to intercept Auth events
      Hub.listen('auth', handleAuth);
      checkUserStatus()
    } else {
      setUserAuth({
        ...userAuth,
        status: NO_CLIENT
      })

    }
  },[])

  // handle Auth events, so when the user signs in / out etc
  const handleAuth = (data) => {
    const {payload} = data;
    switch (payload.event) {
      case 'signedIn':
        checkUserStatus();
        break;
      default:
        break;
    }
  }

// check whether there is a signed in user or not
  const checkUserStatus = async () => {
    try {
      const authSession = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();
      const { enabled } = await fetchMFAPreference();
      if (authSession) {
        window.history.replaceState(null, null, ' ');
        setUserAuth({
          status: SIGNED_IN,
          userMFA: enabled,
          authSession,
          userAttributes,
        });
      }
    } catch (error) {
      if (!window.location.href.includes("id_token")) requireSignIn()
    }
  }

// set status to require sign in
  const requireSignIn = () => {
    setUserAuth({
      ...userAuth,
      status: SIGN_IN
    });
  }

//if user is inactive due to timeout or token expiry
  const userSignOut = async (status) => {
    await signOut().catch(e => console.log(e));
    setUserAuth({
      ...userAuth,
      status: status
    })
  }

  //if user wants to change password while signed in
  const changePassword = async () => {
    setUserAuth({
      ...userAuth,
      status: CHANGE_PASSWORD
    })
  }

// whenever user or its status changes and on first load
  useEffect(() => {
    if (userAuth.status === SIGNED_IN) {
      getConfig()
    }
  }, [userAuth])

// see if the gateway is there
  useEffect(() => {
    if (configFound && config.gateway_access) {
      const gatewayAlive = async () => {
        const gatewayResponse = await fetch(config.gateway_url).catch(() => setGatewayFound(false))
        setGatewayFound((gatewayResponse && gatewayResponse.ok))
      }
      gatewayAlive()
    }
  }, [configFound])

//get this client's config from S3. Note that all attribute names will be in snake case e.g. gateway_access
  const getConfig = async () => {
    const jsonConfig = await jsonFromStorage(`config/${clientId}/front_end_config.json`)
    const clientNameGood = (jsonConfig.client_name.toLowerCase().split(' ').join('')) === clientName;
    if (clientNameGood) {
      setConfig(jsonConfig);
      setConfigFound(true);
    } else {
      console.log("Client name mismatch")
    }
  }

  const repositoryAccess = (config && config.repository_access);
  const possibleFileToDownload = window.location.search.substr(1);
  if (possibleFileToDownload !== "" && userAuth.status === SIGNED_IN && downloadAttempted === false) {
    fileDownload(possibleFileToDownload, undefined, () => setShowFileNotFound(true));
    setDownloadAttempted(true);
  }

  return (
    <div className="App">
      <InactivityTimer signOut={userSignOut}/>
      {(userAuth.status === NO_CLIENT) && <p>There is nothing here</p>}
      {((userAuth.status === FORCE_SIGNED_OUT_INACTIVE) ||
        (userAuth.status === FORCE_SIGNED_OUT_COMMS_ERROR) ||
        (userAuth.status === USER_SIGNED_OUT)) && (
        <SignedOut
          show={true}
          signOutMessage={userAuth.status}
          clearSignedOut={() => (requireSignIn())}/>
      )}
      {userAuth.status === LOADING && (
        <Container>
          <div className="border border-medium">
            <Loader active inline='centered'/>
          </div>
        </Container>
      )}
      {userAuth.status === SIGN_IN && (
        <Container>
          <br/>
          <div className="border border-medium justify-content-center">
            <Login/>
            <SmartPearBrandFooter clientName={clientName}/>
          </div>
        </Container>
      )}
      {(userAuth.status === SIGNED_IN && !userAuth.userMFA) &&
      <Container>
        <div className="border border-medium justify-content-center">
          <SetupMFA email={userAuth.userAttributes.email}/>
          <SmartPearBrandFooter clientName={clientName}/>
        </div>
      </Container>
      }
      {(userAuth.status === SIGNED_IN && userAuth.userMFA?.includes('TOTP')) &&
      <Container fluid>
        <SmartPearHeader email={userAuth.userAttributes.email} signOut={() => userSignOut(USER_SIGNED_OUT)} changePassword={() => changePassword()} clientName={clientName}/>
        <Tabs>
          {gatewayFound && <Tab
            eventKey="gateway"
            title="Gateway">
            <Gateway
              clientId={clientId}
              clientName={clientName}
              email={userAuth.userAttributes.email}
              gatewayUrl={config.gateway_url}
              initialAccessJwt={userAuth.authSession.tokens.accessToken.toString()}
              signOut={userSignOut}/>
          </Tab>}
          {repositoryAccess && <Tab eventKey="repository" title="Repository">
            <Repository
              config={config}
              clientName={clientName}
              showFileNotFound={showFileNotFound}
              setShowFileNotFound={() => setShowFileNotFound(true)}
            />
          </Tab>}
          {!repositoryAccess && configFound && <Tab eventKey="repository" title="Repository"><NoClientAccess/></Tab>}
          {gatewayFound && config && config.graphs_config && (config.graphs_config.length > 0) &&
            <Tab
              eventKey="graphs"
              title={config.graphs_tab_name}
            >
              <Graph
                config={config}
                clientName={clientName}
                authSession={userAuth.authSession}
              />
            </Tab>}
        </Tabs>
        <SmartPearFooter/>
        <FileNotFound
          show={showFileNotFound}
          clearFileNotFound={() => setShowFileNotFound(false)}/>
      </Container>}
      {userAuth.status === CHANGE_PASSWORD && (
        <ForgottenPassword />
      )}
    </div>
  )
}
