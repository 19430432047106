import {Amplify} from 'aws-amplify';

// derive the settings for the oauth part of Auth configuration
function oauth(clientSubdomain,amplifyConfigs) {
    // subdomains using the User ID are not used in development
    const subDomain = (amplifyConfigs.development==="true") ? "" : clientSubdomain;
    const redirectUrl = `https://${subDomain}${amplifyConfigs.url}`;
    return {
        domain : amplifyConfigs[clientSubdomain].hostedUiDomain,
        scope : ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'], 
        redirectSignIn : redirectUrl,
        redirectSignOut : redirectUrl,
        responseType: 'token'
    }
}

const libraryOptions = {
    Storage: {
        S3: {
            prefixResolver: async ({ accessLevel, targetIdentityId }) => {
                if (accessLevel === 'guest') {
                    return 'uploads/';
                } else if (accessLevel === 'initialAccess') {
                    return '';
                } else if (accessLevel === 'protected') {
                    return `protected/${targetIdentityId}/`;
                } else {
                    return `private/${targetIdentityId}/`;
                }
            }
        }
    }
};

// configure AWS amplify for both cognito authentication (Auth) and S3 usage (Storage)
export function configureAmplify(clientSubdomain, amplifyConfigs) {
    Amplify.configure({
        Auth: {
            Cognito: {
                identityPoolId: amplifyConfigs[clientSubdomain].identityPoolId,
                region: amplifyConfigs[clientSubdomain].region,
                userPoolId: amplifyConfigs[clientSubdomain].userPoolId,
                userPoolClientId: amplifyConfigs[clientSubdomain].userPoolWebClientId,
                signUpVerificationMethod: 'code',
                loginWith: {
                    oauth: oauth(clientSubdomain, amplifyConfigs)
                }
            }
        },
        Storage: {
            S3: {
                bucket: amplifyConfigs[clientSubdomain].bucketName,
                region: amplifyConfigs[clientSubdomain].region,
            }
        }
    }, libraryOptions);
} 