import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {resetFocus} from "../common/commonUtilities";
import {TRANSACTION_BUTTON} from "../common/commonUtilities";
import Linkify from 'linkify-react';

export default function FileContents({fileContents, show, onHide}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      onEntered={resetFocus}
      centered
      size='lg'
      backdrop='static'>
      <Modal.Header>
        <Modal.Title>{"Transaction details:"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre style={{overflow: 'scroll', maxHeight: '80vh'}}>
            <Linkify options={{target: "_blank", rel: "noopener noreferrer", validate: {
                    url: (value) => /^https:\/\//.test(value)
                }}}>
                {fileContents}
            </Linkify>
        </pre>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          variant="primary"
          id={TRANSACTION_BUTTON}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
