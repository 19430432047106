import {useIdleTimer} from 'react-idle-timer'
import {FORCE_SIGNED_OUT_INACTIVE} from "../constants";

export default function InactivityTimer({signOut}) {
  // Use the useIdleTimer hook to register the callbacks and options
  useIdleTimer({
    element: document,
    onIdle: () => {
      signOut(FORCE_SIGNED_OUT_INACTIVE)
    },
    onActive: () => {
      console.log('User is active')
    },
    timeout: 1000 * 60 * 60 * 12, // the time (in hours) until the user becomes idle
    events: ["keydown",
      "wheel",
      "DOMMouseScroll",
      "mouseWheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange"] // lists the user events that will trigger the timeout timer to reset
  })

  // Return an empty component as the hook handles everything
  return null
}