import React, {useState} from 'react';
import {resetFocus} from "../common/commonUtilities";
import FileContents from "./FileContents";
import Link from "../common/components/Link";

// displays a hyperlink for a Device ID, when clicked, causes all the Device's details to display
export default function InteractionLink({s3Key, getInteraction, setInteractionLoading}) {
  const [showFileContents, setShowFileContents] = useState(false);
  const [fileContents, setFileContents] = useState("Loading...");

  // clear the S3 key
  const handleFileContentsClose = () => {
    resetFocus();
    setFileContents("Loading...")
    setShowFileContents(false);
  }

  // retrieve the file from s3 then set state accordingly
  const handleFileContentsOpen = async () => {
    setInteractionLoading(true);
    const interaction = await getInteraction(s3Key);
    if (interaction) {
      setFileContents(interaction);
      setShowFileContents(true);
    }
    setInteractionLoading(false);
  }

  return (
    <div>
      <Link
        title="Click to show interaction"
        onClick={handleFileContentsOpen}
        value={"Show"}/>
      {showFileContents && <FileContents
        fileContents={fileContents}
        show={showFileContents}
        onHide={handleFileContentsClose}/>}
    </div>

  );
}
