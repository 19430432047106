import React, {useEffect, useState, useMemo} from 'react';
import {Icon, Loader} from "semantic-ui-react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import NoClientAccess from '../common/components/NoClientAccess'
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LoadingOverlay from "../common/components/SmartLoadingOverlay";
import { fetchAuthSession } from 'aws-amplify/auth';
import { ResizableBox } from 'react-resizable';

// to parse jsx and output rendered React Components
import JsxParser from 'react-jsx-parser'

// and the elements it may use
import SmartSelect from "./inputs/SmartSelect";
import SmartADTFile from "./inputs/SmartADTFile";
import SmartHexBinary from "./inputs/SmartHexBinary"
import SmartEUI64 from "./inputs/SmartEUI64"
import SmartDateTime from "./inputs/SmartDateTime"
import SmartExecutionDateTime from "./inputs/SmartExecutionDateTime"
import SmartGeneral from "./inputs/SmartGeneral"
import SmartDate from "./inputs/SmartDate"
import SmartFile from "./inputs/SmartFile"
import SmartNumber from "./inputs/SmartNumber"
import SmartSevenDayTwoRateSwitching from "./inputs/SmartSevenDayTwoRateSwitching";
import SmartLogPeriod from "./inputs/SmartLogPeriod"
import SmartFuelIdentifier from "./inputs/SmartFuelIdentifier";
import SmartPremisesIdentifier from "./inputs/SmartPremisesIdentifier";
import SmartIdentifier from "./inputs/SmartIdentifier.jsx";
import SmartExtraInfo from "./inputs/SmartExtraInfo";
import SmartCalendar from "./inputs/SmartCalendar";
import SmartEmail from "./inputs/SmartEmail";
import SmartSevenDayTwoRatePeriodOfDay from "./inputs/SmartSevenDayTwoRatePeriodOfDay";
import SmartDynamicSwitching from "./inputs/SmartDynamicSwitching";
import SmartSwitchedOnPeriod from "./inputs/SmartSwitchedOnPeriod";
import SmartOptional from "./inputs/SmartOptional.jsx";

// for searching the crq list
import Select from 'react-select';

import { resetFocus, REACT_SELECT_INPUT, specificOrDefaultIdentifier } from "../common/commonUtilities";

// form for Device ID entry
import Device from './Device';

// does what it says
import ObjectToTableContents from './ObjectToTableContents';

// a table of historic transactions between this client and this Device
import History from "./History";

// the modal form for entering CRQ details and confirming selection
import CrqForm from './CrqForm';

// the alert to show what the submission returned
import CrqSubmitted from './Submitted';

// an authStatus value from SmartAccess
import {FORCE_SIGNED_OUT_COMMS_ERROR, MPAN, MPRN, DEVICE_ID_CAMEL} from "../common/constants";
import DeviceIdLink from "./DeviceIdLink";
import InteractionLink from "./InteractionLink";
import {SelectColumnFilter} from "../common/components/SmartTable";

// types of call that can be made to the Gateway
const DEVICE = "device";                      // request s3 stored device details, in json
const CRQ = "crq";                            // request extra parameters for this CRQ, in react format
const ACCESS = "access";                      // request single entry hash saying if the client has access to Gateway, in json
const SUBMIT_CRQ = "submit";                  // post the CRQ as FormData
const INTERACTION = "interaction";            // request a string containing a formatted version of the interaction specified by key
const HISTORY = 'history'                     // request a string containing JSON with one of the two history tables' data
const MORE_HISTORY = 'moreHistory'
const MOST_RECENT  = 'mostRecent'

// names for the tabs
const MY_HISTORY_TAB = "myHistory";
const DEVICE_HISTORY_TAB = "deviceHistory";

//when no device id is set
const NO_DEVICE = 'no_device';

const CRQ_SELECT = "crq_select";

// for when no request details are known
const EMPTY_REQUEST = {
  device_id: undefined,
  email: undefined,
  client_id: undefined,
  crq: undefined,
  crq_name: undefined,
  params: undefined
};

// deafult sizes for parts of the screen
const INITIAL_DEVICE_HEIGHT   = 250
const INITIAL_HISTORY_HEIGHT  = 435

const NO_HISTORY = []

// the Gateway tab in the app
export default function Gateway({clientId, clientName, email, gatewayUrl, initialAccessJwt, signOut}) {

  const [checkingClientAccess, setCheckingClientAccess] = useState(true);
  const [clientHasAccess, setClientHasAccess] = useState(false);
  const [deviceId, setDeviceId] = useState(NO_DEVICE);
  const [deviceLoading, setDeviceLoading] = useState(false)
  const [deviceLoaded, setDeviceLoaded] = useState(true)
  const [deviceDetails, setDeviceDetails] = useState({deviceSummary: 'Select a device...'});
  const [deviceHistory, setDeviceHistory] = useState(NO_HISTORY);
  const [myHistory, setMyHistory] = useState(NO_HISTORY)
  const [currentAccessJwt, setCurrentAccessJwt] = useState(initialAccessJwt)
  const [relevantHistoryLoading, setRelevantHistoryLoading] = useState(false)
  const [relevantHistoryLoaded, setRelevantHistoryLoaded] = useState(false)
  const [crqSelectList, setCrqSelectList] = useState([]);
  const [noDeviceCrqList, setNoDeviceCrqList] = useState([]);
  const [dccOnlyCrqNos, setDccOnlyCrqNos] = useState([]);
  const [updatesSmiCrqsNos, setUpdatesSmiCrqsNos] = useState([]);
  const [crqSelected, setCrqSelected] = useState(null);
  const [showCrqForm, setShowCrqForm] = useState(false);
  const [requestDetails, setRequestDetails] = useState(EMPTY_REQUEST);
  const [crqSubmitted, setCrqSubmitted] = useState(false);
  const [submissionResponse, setSubmissionResponse] = useState([]);
  const [activeTab, setActiveTab] = useState(MY_HISTORY_TAB);
  const [interactionLoading, setInteractionLoading] = useState(false)
  const [deviceHeight, setDeviceHeight] = useState(INITIAL_DEVICE_HEIGHT)
  const [historyHeight, setHistoryHeight] = useState(INITIAL_HISTORY_HEIGHT)
  const [historyDirection, setHistoryDirection] = useState(MOST_RECENT)
  // -------------------------------------------------------------------------------------------------------------------
  // common function to talk to the Gateway backend server
  // -------------------------------------------------------------------------------------------------------------------
  const fetchDataFromGateway = async (type, request) => {
    const requestFormData = (type === SUBMIT_CRQ) ? request : getFormData(request)
    const result = await fetch(gatewayUrl + type, {
      method: 'POST',
      headers: {'Authorization': "JWT " + await updatedToken(currentAccessJwt)},
      body: requestFormData
    })
    if ((!result) || (result.status === 403)) {
      return signOut(FORCE_SIGNED_OUT_COMMS_ERROR)
    }
    return (type === CRQ) ? await result.text().catch(e => console.log(e)) : await result.json().catch(e => console.log(e))
  }

  // if the currentAccessJwt has expired, get (via Amplify), set and return a new one, otherwise return currentAccessJwt
  const updatedToken = async (currentAccessJwt) => {
    if ((Date.now()/1000) > parseJwt(currentAccessJwt).exp) {
      // Amplify call returns user session with a refreshed access token (provided the session's refresh token is still valid)
      const currentSession = await fetchAuthSession()
      const newToken = currentSession.tokens.accessToken.toString()
      setCurrentAccessJwt(newToken)
      return newToken
    } else {
      return currentAccessJwt
    }
  }

  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  const getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData
  }

  // -------------------------------------------------------------------------------------------------------------------
  // behaviour on renders
  // -------------------------------------------------------------------------------------------------------------------

  // first render
  useEffect(() => {
    getClientAccess()
  }, [])

  //second render and whenever activeTab / deviceDetails changes
  useEffect(() => {
    if (clientHasAccess) {
      if (relevantHistoryLoaded) {
        setRelevantHistoryLoading(false)
      } else if (!relevantHistoryLoading) {
        setRelevantHistoryLoading(true)
      } else {
        getRelevantHistory(historyDirection)
      }
    }
  }, [clientHasAccess, activeTab, deviceDetails, relevantHistoryLoaded, relevantHistoryLoading])

  //when deviceId is set or changed
  useEffect(() => {
    if (deviceId !== NO_DEVICE) {
      if (deviceLoaded) {
        setDeviceLoading(false)
      } else if (!deviceLoading) {
        setDeviceLoading(true);
        setCrqSelected(null);
        setDeviceHistory(NO_HISTORY);
      } else {
        loadDevice()
      }
    }
  }, [deviceId, deviceLoading, deviceLoaded])

  // -------------------------------------------------------------------------------------------------------------------
  // find out what this client can do (and so what this user can do)
  // -------------------------------------------------------------------------------------------------------------------
  const getClientAccess = async () => {
    const requestDetails = {
      client_id: clientId,
      client_name: clientName
    };
    const data = await fetchDataFromGateway(ACCESS, requestDetails).catch(e => console.log(e));
    if (data) {
      setCheckingClientAccess(false);
      setClientHasAccess(data.hasGatewayAccess);
      setNoDeviceCrqList(data.noDeviceCrqList);
      setDccOnlyCrqNos(data.dccOnlyCrqNos);
      setUpdatesSmiCrqsNos(data.updatesSmiCrqs);
      setCrqSelectList(data.noDeviceCrqList);
      resetFocus()
    } else {
      signOut(FORCE_SIGNED_OUT_COMMS_ERROR)
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // timed delay to automatically updated history & optionally Device details
  // quick means everything updated after 3 seconds; not quick is history after 30 seconds
  // -------------------------------------------------------------------------------------------------------------------
  const delayedHistoryUpdate = (quick, smiUpdated) => {
    if (quick) {
      setTimeout(()=> {
        setRelevantHistoryLoaded(false);
        setDeviceLoaded(false)
      },3000)
    } else {
      setRelevantHistoryLoaded(false);
      setTimeout(()=> {
        setRelevantHistoryLoaded(false);
        if (smiUpdated) {
          setDeviceLoaded(false)
        }
      },30000)
    }
  }

  // the created time in milliseconds of the transaction to load more history from. Could be the most recently created transaction displayed (refreshing page for most recent history), or the eldest (loading more history)
  // or 0 if no history loaded (first render)
  function time(relevantHistory, caller) {
    if (relevantHistory[0]) {
      return caller === MOST_RECENT ? relevantHistory[0].created : relevantHistory[relevantHistory.length - 1].created
    }
    return 0
  }

  // -------------------------------------------------------------------------------------------------------------------
  // loads the user / device history in myHistory or deviceHistory in state, so that it can be displayed in <History/>
  // -------------------------------------------------------------------------------------------------------------------
  const getRelevantHistory = async () => {
    const currentlyLoadedHistory = (activeTab === MY_HISTORY_TAB) ? myHistory : deviceHistory
    const requestDetails = {
      ...((activeTab === MY_HISTORY_TAB) ? {client_email: email} : {device_id: deviceId}),
      client_id:             clientId,
      client_name:           clientName,
      requested_time_period: historyDirection, // ie most recent (refresh) or more history
      requested_time:        time(currentlyLoadedHistory, historyDirection), // what time to start looking for (first or last transaction currently loaded)
    };
    const newHistory     = await fetchDataFromGateway(HISTORY, requestDetails).catch(e => console.log(e));
    const updatedHistory = historyDirection === MOST_RECENT ? newHistory.concat(currentlyLoadedHistory) : currentlyLoadedHistory.concat(newHistory);
    if (newHistory) {
      (activeTab === MY_HISTORY_TAB) ? setMyHistory(updatedHistory) : setDeviceHistory(updatedHistory)
      // if we're loading more history don't reset focus to the top of the page as the button
      // they pressed and the results they want will be displayed at the bottom of the page
      if (deviceId !== NO_DEVICE && historyDirection === MOST_RECENT) resetFocus()
      setRelevantHistoryLoaded(true)
    } else {
      signOut(FORCE_SIGNED_OUT_COMMS_ERROR)
    }
  }

  // column heading for history
  const DEVICE_HISTORY_COLUMNS = [
    {
      Header: 'Show',
      accessor: 'transaction_key',
      width: '9%',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({cell: {value}}) => {
        return (
          <InteractionLink
            s3Key={value}
            getInteraction={getInteraction}
            setInteractionLoading={setInteractionLoading}/>
        )
      }
    },
    {Header: 'ID No.', width: '11%', accessor: 'id_no'},
    {Header: 'Action', accessor: 'action'},
    {Header: 'SRV', width: '10%', accessor: 'srv'},
    {Header: 'Type', width: '10%', accessor: 'type', Filter: SelectColumnFilter, filter: 'includes',},
    {Header: 'Created', accessor: 'created_datetime'},
  ]

  const MY_HISTORY_COLUMNS = [
    {
      Header: 'Identifier',
      accessor: 'device_id',
      width: '15%',
      Cell: ({cell: {value}}) => {
        return (<DeviceIdLink setDeviceId={setDeviceId} setDeviceLoaded={setDeviceLoaded} deviceId={value}/>)
      }
    },
    ...DEVICE_HISTORY_COLUMNS,
  ]

  const MY_HISTORY_COLUMNS_MEMO = useMemo(() => MY_HISTORY_COLUMNS, [])
  const DEVICE_HISTORY_COLUMNS_MEMO = useMemo(() => DEVICE_HISTORY_COLUMNS, [])

  // to retrieve client transactions from History
  // return a (nicely formatted) version of the client interaction
  // it will be in the format originally requested (so json or xml)
  const getInteraction = async (transaction_key) => {
    const requestDetails = {
      transaction_key: transaction_key,
      client_id: clientId,
      client_name: clientName
    };
    const data = await fetchDataFromGateway(INTERACTION, requestDetails).catch(e => console.log(e));
    if (data) {
      return data.interaction
    } else {
      signOut(FORCE_SIGNED_OUT_COMMS_ERROR)
    }
  }

  // -------------------------------------------------------------------------------------------------------------------
  // get device details from the Gateway (if there are any - it will display a message if not)
  // then get the Device history from s3
  // -------------------------------------------------------------------------------------------------------------------
  const loadDevice = async () => {
    const requestDetails = {
      device_id: deviceId,
      client_id: clientId,
      client_name: clientName
    };
    setHistoryDirection(MOST_RECENT)
    const data = await fetchDataFromGateway(DEVICE, requestDetails).catch(e => console.log(e));
    if (data) {
      setCrqSelectList(data.crqList);
      setRelevantHistoryLoaded(false)
      setDeviceDetails(data.deviceDetails)
      setDeviceLoaded(true)
      resetFocus()
    } else {
      signOut(FORCE_SIGNED_OUT_COMMS_ERROR)
    }
  }

  // clear device details, including any prior crq submission, if the Device ID field is edited
  const handleDeviceChange = () => {
    setDeviceLoaded(false)
    setDeviceId(NO_DEVICE)
    setCrqSelectList(noDeviceCrqList);
    setDeviceHistory(NO_HISTORY);
    setCrqSelected(null);
  }

  // -------------------------------------------------------------------------------------------------------------------
  // remember the crq selected, clearing any details of a prior crq submission
  // -------------------------------------------------------------------------------------------------------------------
  const handleCrqSelection = (selectedCrq) => {
    setCrqSelected(selectedCrq);
  }

  // return the details needed in a 'crq' request to the Gateway
  // its also used in the display of the CRQ form
  const gatewayCrqRequestDetails = () => {
    const noDeviceCrq = noDeviceCrqList.map(crqObject => {return crqObject["value"]}).includes(crqSelected.value)
    return {
      device_id: noDeviceCrq ? "00-00-00-00-00-00-00-00" : (((deviceDetails && deviceDetails.deviceID) ? deviceDetails.deviceID.toUpperCase() : "")),
      email: email,
      client_id: clientId.toUpperCase(),
      client_name: clientName,
      crq:  crqSelected.value,
      crq_name: crqSelected.label,
      params: null
    }
  }

  // request the specific input parameters for this crq, if one is selected
  const handleCrqFormOpen = async (e) => {
    e.preventDefault();
    if (crqSelected === null) return;
    const requestDetails = gatewayCrqRequestDetails();
    const data = await fetchDataFromGateway(CRQ, requestDetails).catch(e => console.log(e));
    if (data) {
      requestDetails.params =
        <JsxParser
          components={{
            Form,
            Col,
            Row,
            Button,
            SmartSelect,
            SmartADTFile,
            SmartHexBinary,
            SmartEUI64,
            SmartDate,
            SmartExecutionDateTime,
            SmartFile,
            SmartGeneral,
            SmartNumber,
            SmartDateTime,
            SmartLogPeriod,
            SmartFuelIdentifier,
            SmartPremisesIdentifier,
            SmartIdentifier,
            SmartSevenDayTwoRateSwitching,
            SmartOptional,
            SmartExtraInfo,
            SmartCalendar,
            SmartEmail,
            SmartSevenDayTwoRatePeriodOfDay,
            SmartSwitchedOnPeriod,
            SmartDynamicSwitching
          }}
          jsx={data}/>;
      setRequestDetails(requestDetails);
      setShowCrqForm(true);
    } else {
      signOut(FORCE_SIGNED_OUT_COMMS_ERROR)
    }
  }

  // Submit the complete and validated CRQ to the Gateway as FormData, clear the CrqForm and the Crq selection
  // The response will allow a modal message to be displayed
  const submitCrq = async (formData) => {
    const ackOrError = await fetchDataFromGateway(SUBMIT_CRQ, formData).catch(e => console.log(e));
    setSubmissionResponse(ackOrError ? ackOrError.response : "Unexpected error. Trying again may work.");
    setShowCrqForm(false);
    setCrqSubmitted(parseInt(crqSelected['label'].substr(3,4)));
    setCrqSelected(null);
  }

  //handle resizing of the Device section
  const onDeviceResize = (event, {element, size, handle}) => {
    setDeviceHeight(size.height);
  };
  //handle resizing of the History section
  const onHistoryResize = (event, {element, size, handle}) => {
    setHistoryHeight(size.height);
  };

  // handle clicking of the 'Load More History...' button
  const loadMoreHistory = () => {
    setHistoryDirection(MORE_HISTORY);
    setRelevantHistoryLoaded(false)
  };
  // -------------------------------------------------------------------------------------------------------------------
  // Gateway - so wrapper for all client request based access
  // -------------------------------------------------------------------------------------------------------------------
  return (
    <div className="Gateway">
      {(checkingClientAccess) &&
      <div className="border border-medium">
        <Loader active inline='centered'/>
      </div>
      }
      {(clientHasAccess) &&
      <div className="border border-medium" align="left">
        <div className="p-1">
          {/* if the client has access, let them enter Devices, and show device details when they have done so*/}
          <Row>
            <Col xs="4"><Device
                deviceId={deviceId}
                handleDeviceChange={handleDeviceChange}
                setDeviceId={(id) => {
                  if (id === deviceId) {
                    loadDevice()
                  } else {
                    setDeviceId(id)
                  }
                }}/></Col>
            <Col xs="8">
              {<Form onSubmit={handleCrqFormOpen} align="left">
                <Form.Label>Select an action:</Form.Label>
                <InputGroup>
                  <Col style={{padding: '0'}}>
                    <Select
                      id={CRQ_SELECT}
                      name={CRQ_SELECT}
                      required
                      value={crqSelected}
                      onChange={handleCrqSelection}
                      options={crqSelectList}
                      inputId={REACT_SELECT_INPUT}/>
                  </Col>
                    <Button type="submit">
                      <Icon name="play"/>
                    </Button>
                </InputGroup>
              </Form>}
            </Col>
          </Row>
          <CrqSubmitted
            show={crqSubmitted!==false}
            response={submissionResponse}
            clearSubmitted={() => setCrqSubmitted(false)}
            delayedHistoryUpdate={() => {
              delayedHistoryUpdate(dccOnlyCrqNos.includes(crqSubmitted), updatesSmiCrqsNos.includes(crqSubmitted));
            }
            }
          />
          {/* a table with all s3 Device details */}
          {/* any time anything is loading*/}
          <LoadingOverlay active={deviceLoading}>
            {deviceDetails &&
            <ResizableBox resizeHandles={['s']} axis={'y'} height={INITIAL_DEVICE_HEIGHT} width={Infinity} onResize={onDeviceResize}>
              <div style={{overflow: 'auto', maxHeight: deviceHeight}}>
                <Table striped bordered hover size="sm">
                  <tbody>
                  <ObjectToTableContents
                      setDeviceId={(id) => setDeviceId(id)}
                      setDeviceLoaded={setDeviceLoaded}
                      targetObject={deviceDetails}/>
                  </tbody>
                </Table>
              </div>
            </ResizableBox>}
          </LoadingOverlay>
          {/* a table of contents from a list of this clients interaction with this Device*/}
          <LoadingOverlay
            active={
              (deviceLoading && (activeTab === DEVICE_HISTORY_TAB)) ||
              (relevantHistoryLoading)||
              interactionLoading}
            >
            <ResizableBox resizeHandles={['s']} axis={'y'} height={INITIAL_HISTORY_HEIGHT} width={Infinity} onResize={onHistoryResize}>
              <Tabs
                defaultActiveKey={activeTab}
                onSelect={e => {
                  setHistoryDirection(MOST_RECENT)
                  setRelevantHistoryLoaded(false)
                  if (e !== activeTab) setActiveTab(e)
                }}>
                <Tab eventKey={MY_HISTORY_TAB} title={<span className="emoji">My History 🔄</span>}>
                  <History
                    columns={MY_HISTORY_COLUMNS_MEMO}
                    maxHistoryHeight={historyHeight}
                    data={myHistory}/>
                </Tab>
                {/* Identifier History tab depends on whatever the gateway thinks the identifier is */}
                {deviceId !== NO_DEVICE && <Tab eventKey={DEVICE_HISTORY_TAB} title={<span className="emoji">{specificOrDefaultIdentifier(deviceDetails[MPAN] || deviceDetails[MPRN] || deviceDetails[DEVICE_ID_CAMEL])} History 🔄</span>}>
                  <History
                    columns={DEVICE_HISTORY_COLUMNS_MEMO}
                    maxHistoryHeight={historyHeight}
                    data={deviceHistory}/>
                </Tab>}
              </Tabs>
            </ResizableBox>
          </LoadingOverlay>
          {/* The modal form to to get user confirmation they want to action a request, including entering parameters*/}
          <CrqForm
            requestDetails={requestDetails}
            show={showCrqForm}
            onHide={() => setShowCrqForm(false)}
            submitCrq={submitCrq}/>
        </div>
      </div>}
      {(!clientHasAccess) && <NoClientAccess/>}
      <Button size="sm" onClick={() => loadMoreHistory()}>Load More History...</Button>
    </div>
  )
}
