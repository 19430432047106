import React, {useState} from "react";
import {resetPassword, confirmResetPassword} from 'aws-amplify/auth';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {PASSWORD_REQUIREMENTS} from '../commonUtilities';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// define steps
const EMAIL = 'EMAIL'
const NEW_PASSWORD = 'NEW_PASSWORD'
const CONFIRMED = 'CONFIRMED'

export default function ForgottenPassword() {

  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nextStep, setNextStep] = useState(EMAIL)
  const [email, setEmail] = useState("")

  const clearValidated = () => {
    setValidated(false);
    setErrorMessage("")
  }

  // Handles what happens when user clicks button to submit email for password reset.
  const checkEmailForm = async (event) => {
    const email = event.target.potentialEmail.value;
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setValidated(true);
    } else {
      try {
        await resetPassword({username: email});
        setValidated(false);
        setEmail(email);
        setNextStep(NEW_PASSWORD);
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  };


  // Handles what happens when user clicks button to submit verification code and new password.
  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setValidated(true);
    } else if (event.target.newPassword.value !== event.target.confirmNewPassword.value) {
      setErrorMessage("Passwords do not match.");
    } else {
      try {
        await confirmResetPassword({
          username: email,
          newPassword: event.target.newPassword.value,
          confirmationCode: event.target.authorisationCode.value,
        });
        setNextStep(CONFIRMED);
        setValidated(false);
      } catch (error) {
        if (error.code === "InvalidParameterException" || error.code === "InvalidPasswordException") {
          setErrorMessage(PASSWORD_REQUIREMENTS)
        } else {
          setErrorMessage(error.message)
        }
      }
    }
  };

  return (
    <div>
      <Row>
        <Col/>
        <Col>
          {nextStep === EMAIL &&
          < Form noValidate onSubmit={checkEmailForm} className={validated ? "was-validated" : ""}>
            <Form.Label>Please enter your email</Form.Label>
            <Form.Control
              autoFocus
              required
              id="potentialEmail"
              type="email"
              onChange={clearValidated}/>
            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
            <br/>
            <div style={{display: "block"}}>
              <p style={{color: "red", fontSize: "11px"}}>{errorMessage}</p>
            </div>
            <br/>
            <div className="d-grid gap-2">
              <Button type="submit" title="Click to submit your email for password reset">
                Submit
              </Button>
            </div>
            <br/>
          </Form>}
          {nextStep === NEW_PASSWORD &&
          < Form noValidate onSubmit={handlePasswordSubmit} className={validated ? "was-validated" : ""}>
            <p>A verification code has been sent to your email to confirm your password change request. <br/>
              Please confirm your verification code below.</p>
            <Form.Label>Verification code</Form.Label>
            <Form.Control
              autoFocus
              required
              pattern="^[0-9]{6}$"
              id="authorisationCode"
              type="password"
              onChange={clearValidated}
            />
            <Form.Control.Feedback type="invalid">Please enter a six-digit number only.</Form.Control.Feedback>
            <Form.Label>New password</Form.Label>
            <Form.Control
              autoFocus
              placeholder="Enter your new password"
              required
              id="newPassword"
              type="password"
              onChange={clearValidated}
            />
            <Form.Label>Confirm new password</Form.Label>
            <Form.Control
              type="password"
              id="confirmNewPassword"
              required
              placeholder="Re-type your new password"
              onChange={clearValidated}
            />
            <br/>
            <div style={{display: "block"}}>
              <p style={{color: "red", fontSize: "11px"}}>{errorMessage}</p>
            </div>
            <br/>
            <div className="d-grid gap-2">
              <Button
                type="submit"
                title="Click here to change your password"
              >
                Change password
              </Button>
            </div>
            <br/>
          </Form>}
          {nextStep === CONFIRMED &&
          <Form>
            <Form.Label>Password has been successfully changed</Form.Label>
            <div className="d-grid gap-2">
              <Button
                onClick={() => {
                  window.location.reload()
                }}
                title="Click here to continue">
                Continue
              </Button>
            </div>
            <br/>
          </Form>}
        </Col>
        <Col/>
      </Row>
    </div>
  )
}
