import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CRQ_FORM_FOCUS} from "../../common/constants";

//EUI64 fragment for device ID's- fixed formatting, pattern validation (ensuring XX-XX-XX-XX-XX-XX-XX-XX) and feedback relating to this
export default function SmartEUI64(props) {
  return (
    <Form.Group as={Row} className="p-2">
      <Form.Label column sm="3">{props.label}</Form.Label>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
        <Form.Control
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          required={props.required}
          pattern="^[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}-[A-Fa-f0-9]{2}$"
          title={props.title}
          size="23"
          maxLength="23"
          minLength="23"
          defaultValue={props.defaultValue}/>
        <Form.Control.Feedback type="invalid">Enter in the format Aa-22-33-44-55-66-77-88 using a-f, A-F and
          0-9</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}


