import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';

export default function SmartPearBrand({ clientName }) {
  const clientLogo = clientName + '.png';
  const clientLogoPath = `/client_logos/${clientLogo}`;
  const [clientLogoExists, setClientLogoExists] = useState(true);

  const handleLogoError = () => {
    // If the clientLogo image fails to load, set the state to indicate it doesn't exist
    setClientLogoExists(false);
  };

  return (
    <Navbar.Brand>
      <img
        alt="Smart Pear logo"
        src="pear-logo-192x192.png"
        width="40"
        height="40"
        className="d-inline-block align-center"
      />
      Smart Pear {clientLogoExists ? 'for ' : ''}
      {clientLogoExists && (
        <img
          alt="client logo"
          src={clientLogoPath}
          width="180"
          height="40"
          className="d-inline-block align-center"
          onError={handleLogoError}
        />
      )}
    </Navbar.Brand>
  );
}
