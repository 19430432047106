import React, { useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'moment-timezone';
import Form from 'react-bootstrap/Form';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {CRQ_FORM_FOCUS} from '../../common/constants';
import Col from 'react-bootstrap/Col';

moment.locale('en-GB');
moment.tz.setDefault('UTC');

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);
const resourceMap = [
  { resourceId: 'Monday', resourceTitle: 'Mon' },
  { resourceId: 'Tuesday', resourceTitle: 'Tue' },
  { resourceId: 'Wednesday', resourceTitle: 'Wed' },
  { resourceId: 'Thursday', resourceTitle: 'Thu' },
  { resourceId: 'Friday', resourceTitle: 'Fri' },
  { resourceId: 'Saturday', resourceTitle: 'Sat' },
  { resourceId: 'Sunday', resourceTitle: 'Sun' },
]

export default function SmartCalendar() {
  const [events, setEvents] = useState([]);

  const moveEvent = ({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) => {
    if (errorChecks(start, end, resourceId, events, event)) {
      let allDay = event.allDay;
      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true;
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false;
      }
      const nextEvents = events.map((existingEvent) =>
          existingEvent.id === event.id
              ? { ...existingEvent, start, end, resourceId, allDay }
              : existingEvent
      );
      setEvents(nextEvents);
    }
  };

  const eventsOverlap = (start, end, eventsInDay, changingEvent) => {
    return eventsInDay.some((event) => {
      if (changingEvent && changingEvent.id === event.id) {
        return false;
      } else {
        return start < event.end && event.start < end;
      }
    });
  };

  // return true if there are no errors, otherwise return false
  const errorChecks = (start, end, resourceId, existingEvents, event) => {
    const eventsInDay = existingEvents.filter(
        (existingEvent) => resourceId === existingEvent.resourceId && existingEvent !== event
    );
    if (eventsInDay.length === 4) {
      alert(`${resourceId} already contains 4 periods. Delete one and try again.`);
      return false;
    } else if (eventsOverlap(start, end, eventsInDay, event)) {
      alert(`This event overlaps with an existing event.`);
      return false;
    } else if (start.toString() === end.toString() || start.getUTCDay() !== end.getUTCDay()) {
      return false;
    } else {
      return true;
    }
  };

  const resizeEvent = ({ start, end, resourceId, event }) => {
    if (errorChecks(start, end, resourceId, events, event)) {
      const nextEvents = events.map((existingEvent) =>
          existingEvent.id === event.id ? { ...existingEvent, start, end, resourceId } : existingEvent
      );
      setEvents(nextEvents);
    }
  }

  const handleCreate = ({start, end, resourceId}) => {
    if ((errorChecks(start, end, resourceId, events))) {
      const idList = events.map(a => a.id)
      const newId = idList.length === 0 ? 1 : Math.max(...idList) + 1
      setEvents(
        [
          ...events,
          {
            start,
            end,
            id: newId,
            resourceId
          }
        ]
      )
    }
  }

  const handleDelete = (event) => {
    setEvents((prevEvents) => {
      const updatedEvents = [...prevEvents];
      const idx = updatedEvents.indexOf(event);
      updatedEvents.splice(idx, 1);
      return updatedEvents;
    });
  };

  return (
      <>
        <Col sm="9" name={CRQ_FORM_FOCUS}>
          <Form.Control
            id="events"
            name="events"
            value={JSON.stringify(events)}
            hidden
            readOnly
          />
        </Col>
        <DragAndDropCalendar
          className="p-2"
          style={{ height: 400 }}
          selectable
          events={events}
          onEventDrop={moveEvent}
          localizer={localizer}
          onEventResize={resizeEvent}
          onSelectSlot={handleCreate}
          onDoubleClickEvent={handleDelete}
          defaultDate={new Date("3/12/2015")}
          step={30}
          timeslots={2}
          defaultView={Views.DAY}
          views={["day"]}
          resources={resourceMap}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          toolbar={false}
          formats={{
            timeGutterFormat: (date, culture, localizer) =>
              localizer.format(date, "HH:mm z", culture),

            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              localizer.format(start, "HH:mm z", culture) +
              " - " +
              localizer.format(end, "HH:mm z", culture),

            selectRangeFormat: ({ start, end }, culture, localizer) =>
              localizer.format(start, "HH:mm z", culture) +
              " - " +
              localizer.format(end, "HH:mm z", culture),
          }}
        />
      </>
  );
}