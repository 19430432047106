import React from 'react';
import ExtraInfoLink from "../ExtraInfoLink";

//Allow for an extra info link on a crq form
export default function SmartExtraInfo(props) {
  return (
    <ExtraInfoLink
      title={props.title}
      content={props.content}
    />
  )
}

