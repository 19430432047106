import React, {Fragment} from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

export default function PathBreadcrumb(props) {
  const pathParts = props.selectedPath.replace(/\/$/, "").split("/");
  const paths = [{absolute: "", displayName: "Smart Repository", selected: props.selectedPath === ""}]
  if (props.selectedPath !== "") {
    let pathFollowed = ""
    for (let part of pathParts) {
      pathFollowed = pathFollowed + part + "/"
      paths.push({absolute: pathFollowed, displayName: part, selected: props.selectedPath === pathFollowed})
    }
  }

  return (
    <div className="border border-medium p-1">
      <Breadcrumb>
        {paths.map((path, index) => {
          return <Fragment key={index}>
            {path.selected &&
            <Fragment><Breadcrumb.Item active><strong>{path.displayName}</strong></Breadcrumb.Item></Fragment>}
            {!path.selected && <Fragment><Breadcrumb.Item
              title="Click to go to this folder"
              onClick={(e) => {
                e.preventDefault();
                props.onSelectPath(path.absolute)
              }}
            ><strong>{path.displayName}</strong></Breadcrumb.Item>
            </Fragment>}
          </Fragment>
        })}
      </Breadcrumb>
    </div>
  )
}
