import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const refreshButton = "refresh-button"

// display a Modal containing an error when a selected file in the Bucket List no longer exists in S3
export default function FileNotFound({show, clearFileNotFound}) {
  return (
    <Modal
      size='lg'
      show={show}
      onHide={clearFileNotFound}
      onEntered={() =>
      {document.getElementById(refreshButton).focus()}
      }
    >
      <Modal.Header>
        <Modal.Title>File not found</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>The selected file does not correspond to a file currently held by Smart Pear.</p>
        <p>If this problem persists, please contact support.</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button
            onClick={clearFileNotFound}
            variant={"warning"}
            id={refreshButton}
          >
            OK
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
