import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

export default function SmartPearFooter() {
  return (
    <Navbar expand="lg" className="navbar-nav nav-fill w-100 p-1">
      <div className="container-fluid">
        <Nav.Item className="justify-content-left">
          <Nav.Link
            href="https://www.smartpear.co.uk/contact"
            title="Click to contact us"
            target="_blank"
            rel="noopener noreferrer">Contact us
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="justify-content-center">
          <Nav.Link
            disabled
            style={{color: 'olive'}}>Smart Meter Services powered by Smart Pear
          </Nav.Link>
        </Nav.Item>
        <Nav.Item className="justify-content-right">
          <Nav.Link
            href="https://www.smartpear.co.uk/"
            title="Click to find out about us"
            target="_blank"
            rel="noopener noreferrer">About us
          </Nav.Link>
        </Nav.Item>
      </div>
    </Navbar>
  )
}

