import React, {Fragment} from 'react';
import {Loader, Segment, Icon, Header, Table} from 'semantic-ui-react';
import {fileDownload} from '../common/fileDownload';
import {reverseTimestampRemoved} from '../common/commonUtilities'

export default function BucketList(props) {

  // make the s3Key human readable
  const formatKey = (key) => {
    const pathParts = key.replace(/\/$/, "").split("/");
    const dateTimeStamp = dateTimeFromFileName(key)
    const rider = (dateTimeStamp === "") ? "" : ` (${dateTimeStamp})`
    return reverseTimestampRemoved(pathParts[pathParts.length - 1]) + rider;
  }

  // the time stamp in yy-mm-ddThh:mm:ss:mm or the empty string if there is no valid time stamp
  const dateTimeFromFileName = (s3Key) => {
    const s3KeyParts = s3Key.split('!');
    if ((s3KeyParts.length >= 2) && (s3KeyParts[1].length === 13)) {
      const reverseTimestampNo = Number(s3Key.split('!')[1]);
      const dateTime = (new Date((new Date('2100-01-01T00:00:00').getTime() - reverseTimestampNo))).toISOString();
      return (reverseTimestampNo === 0) ? "" : dateTime
    } else {
      return ""
    }
  }

  return (
    <div style={{overflow: 'scroll', maxHeight: '70vh'}}>
      {props.loading && (
        <div className="border border-medium">
          <Loader active inline='centered'/>
        </div>
      )}
      {!props.loading && (
        <Fragment>
          <Table celled striped>
            <Table.Body>
              {props.keys.length > 0 && (
                props.keys.map((value, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {value.endsWith("/") &&
                        (
                          <Fragment>
                            <Icon name='folder'/>
                            <a href="/#"
                               title="Click to open"
                               onClick={(e) => {
                                 e.preventDefault();
                                 props.onSelectFolder(value);
                               }}>{formatKey(value)}</a>
                          </Fragment>
                        )}
                        {!value.endsWith("/") &&
                        (
                          <Fragment><Icon name='file alternate outline'/>
                            <a href="/#"
                               title="Click to download"
                               onClick={(e) => {
                                 e.preventDefault();
                                 fileDownload(value, props.clientName, props.setShowFileNotFound);
                               }}>{formatKey(value)}</a>
                          </Fragment>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                }))}
            </Table.Body>
          </Table>
          {props.keys.length === 0 && (
            <Segment placeholder>
              <Header icon>
                <Icon name='file alternate outline'/>
                No files found
              </Header>
            </Segment>
          )}
        </Fragment>
      )}
    </div>
  )
}
