import React, {useState} from 'react';
import {Form, Col, Row} from "react-bootstrap";
import SmartEUI64 from "./SmartEUI64";
import SmartGeneral from "./SmartGeneral";
import {identifierType, isIdentifier} from "../../common/commonUtilities";
import {MPAN, MPRN, DEVICE_ID} from "../../common/constants";

export default function SmartFuelIdentifier(props) {
  const device_id_value = document.getElementById("device-id").value
  const [identifier, setIdentifier] = useState(!props.excludeDeviceId && isIdentifier(device_id_value) ? identifierType(device_id_value) : MPAN);
  const handleChange = event => {
    setIdentifier(event.target.value);
  };
  return (
    <>
      <Row className="p-2">
        <Col sm="3">
          <Form.Label>{props.excludeDeviceId ? "Please input a Meter Point number:" : "Please select and input a fuel identifier:"}
          </Form.Label>
        </Col>
        <Col xs="3" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={MPAN}
              onChange={handleChange}
              checked={identifier === MPAN}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">MPAN</Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="3" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={MPRN}
              onChange={handleChange}
              checked={identifier === MPRN}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">MPRN</Form.Check.Label>
          </Form.Check>
        </Col>
        {!props.excludeDeviceId && <Col xs="3" className="p-3">
          <Form.Check>
            <Form.Check.Input
              type="radio"
              value={DEVICE_ID}
              onChange={handleChange}
              checked={identifier === DEVICE_ID}
            />
            <Form.Check.Label bsPrefix="form-check-label text-dark">Device ID</Form.Check.Label>
          </Form.Check>
        </Col>}
      </Row>
      {identifier === MPAN &&
      <SmartGeneral
        // MPRN needs to be lowercase for request to gateway
        name={MPAN.toLowerCase()}
        placeholder="Enter the MPAN"
        required
        minlength="13"
        maxlength="13"
        size="13"
        pattern="^[ _\-\.0-9a-zA-Z]{13,13}$"
        title="Enter the MPAN"
        feedback="Enter 13 characters using a-z, A-Z, 0-9 and . - _"
      />}
      {identifier === MPRN &&
      <SmartGeneral
          // MPRN needs to be lowercase for request to gateway
        name={MPRN.toLowerCase()}
        placeholder="Enter the MPRN"
        required
        maxLength="10"
        minLength="1"
        pattern="^[ _\-\.0-9a-zA-Z]{1,10}$"
        title="Enter the MPRN"
        feedback="Enter between 1 and 10 characters using a-z, A-Z, 0-9 and . - _"
      />}
      {!props.excludeDeviceId && identifier === DEVICE_ID &&
      <SmartEUI64
          // 'Device ID' needs to be 'device_id' in request to gateway
        name={DEVICE_ID.split(' ').join('_').toLowerCase()}
        placeholder="Enter the ESME or GSME ID"
        required
        title="Enter the ESME ID or GSME ID "
        defaultValue={identifierType(document.getElementById("device-id").value) === DEVICE_ID ? document.getElementById("device-id").value : ""}
      />}
    </>
  )
}
