import React, { useState } from 'react';
import { Form, Col, Row } from "react-bootstrap";

export default function SmartIdentifier(props) {
  const identifierList = JSON.parse(props.identifiers);
  const [identifier, setIdentifier] = useState(identifierList[0]);

  const handleChange = event => {
    setIdentifier(event.target.value);
  };

  const renderFragments = () => {
    const fragments = React.Children.toArray(props.children);
    return fragments.filter(fragment => fragment.props.identifier === identifier);
  };

  const renderRadioButtons = () => {
    return identifierList.map((id) => (
      <Col xs="3" className="p-3" key={id}>
        <Form.Check>
          <Form.Check.Input
            type="radio"
            value={id}
            onChange={handleChange}
            checked={identifier === id}
          />
          <Form.Check.Label bsPrefix="form-check-label text-dark">{id}</Form.Check.Label>
        </Form.Check>
      </Col>
    ));
  };

  return (
    <>
      <Row className="p-2">
        <Col sm="3">
          <Form.Label>Please select the {props.label} type to set:</Form.Label>
        </Col>
        {renderRadioButtons()}
      </Row>
      {renderFragments().map((fragment, index) => (
        <React.Fragment key={index}>{fragment}</React.Fragment>
      ))}
    </>
  );
}