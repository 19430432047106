import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CRQ_FORM_FOCUS} from "../../common/constants";

//Fragment for HexBinary variables ensuring consistent pattern validation and formatting
export default function SmartHexBinary(props) {
  return (
    <Form.Group as={Row} className="p-2">
      <Form.Label column sm="3">{props.label}</Form.Label>
      <Col sm="9" name={CRQ_FORM_FOCUS}>
        <Form.Control
          name={props.name}
          id={props.name}
          placeholder={props.placeholder}
          required
          pattern={props.pattern}
          title={props.title}
          size={props.size}
          maxLength={props.maxLength}
          minLength={props.minLength}
          defaultValue={props.defaultValue}/>
        <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}

